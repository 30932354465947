import { useState } from "react"
import {post} from '../../libs/api';
import { Errors } from '../common/msg'; 

import { TextField, useValidation } from '../../components/form';

const Setup2FA = ({uid, token, onComplete}) => {

  const [code, setCode] = useState('');
  const [errors, setErrors] = useState([]);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleSubmit = async() => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    setErrors([]);
    const data = await post('/auth/match', {uid, token, code: parseInt(code)});
    if(data.status != 200){
      setErrors([data.error]);
      return;
    }
    if(data.data?.token){
      onComplete(data.data.token, data.data.status);
    }
  }

  return (
    <>
    <div className="nk-block-head text-center">
        <h4 className="nk-block-title">2-Step Verification</h4>
    </div>
    <div className="nk-block-content text-center">
      
      <p>Verify with Google Authenticator.</p>
      <p><strong>Please enter your 6-digit code below to verify.</strong></p>

      <Errors errors={errors}/>
      <div className="form-validate is-alter">
          <div className="form-group">
              <div className="form-control-wrap mb-3">
                <TextField className="form-control" placeholder="Authentication code"
                    value={code} required={true} showError={showError} minLength={6}
                    onValidate={(valid) => setFieldValid('code', valid)}
                    onChange={(e) => setCode(e.target.value)}/>
              </div>
          </div>
          <div className="form-group">
              <button type="button" className="btn btn-block btn-primary"
                onClick={handleSubmit}>Verify Code</button>
          </div>
      </div>
      <div className="gap gap-lg"></div>
      <p className="text-soft mx-auto w-max-250px">Lost access to your authenticator app? Please feel free to contact our team.</p>
    </div>
  </>
  )
}

export default Setup2FA;
