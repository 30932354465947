import { showAmount } from "../../libs/util";

const Component = ({card}) => {
  return (
    <div className="card card-full card-bordered card-wg on-left is-primary">
    <div className="card-inner">
      {card && (
        <div className="nk-wgacc">
          <div className="nk-wgacc-title text-base">
              Total {card?.currency} Invested              
          </div>
          <div className="nk-wgacc-sub">
              <div className="nk-wgacc-amount">
                  <div className="number number-md">
                    {showAmount(card?.invest_balance, card?.currency)} <small className="currency">{card?.currency}</small>
                  </div>
              </div>
          </div>          
          {/* <div className="nk-wgacc-title text-base">
              Approx Profit
              <em className="icon ni ni-info fs-13px text-soft nk-tooltip" title="The available balance in your investment account."></em>
          </div>
          <div className="nk-wgacc-sub">
              <div className="nk-wgacc-amount">
                  <div className="number number-md">0.00 <small className="currency">{card.currency}</small></div>
              </div>
          </div>                            */}
        </div>
      )}
    </div>
  </div>    
  )
}

export default Component;
