import { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Component = ({currency}) => {

  const [css, setCss] = useState(null);
  const [type, setType] = useState(null);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if(!currency){
      return;
    }
    let c = currency.toLowerCase();

    if(c == 'btc'){
      setCss('ni-sign-btc-alt');
      setTitle('Bitcoin');
      setType('ni');
    }
    else if(c == 'eth'){
      setCss('ni-sign-eth-alt');
      setTitle('Ethereum');
      setType('ni');
    }
    else if(c == 'usd' || c == 'usdc'){
      setCss('ni-sign-usd-alt');
      setTitle('USDC');
      setType('ni');
    }

    //Expand to Transaction Type
    else if(c == 'de'){
      setCss('circle-plus');
      setTitle('Deposit');
      setType('fa');
    } else if(c == 'in'){
      setCss('cash-register');
      setTitle('Order');
      setType('fa');
    } else if(c == 'wd'){
      setCss('circle-minus');
      setTitle('Withdrawal');
      setType('fa');
    } else if(c == 're'){
      setCss('hand-holding-dollar');
      setTitle('Redepmtion');
      setType('fa');
    } else if(c == 'sw'){
      setCss('money-bill-transfer');
      setTitle('Swap');
      setType('fa');
    }

  }, [currency])


  if(type == 'ni') {
    return (
        <h2><em className={"bg-lighter icon ni " + css} title={title}/></h2>
    )
  } else {
    return (
        <div className="nk-cm-item">
          <div className="nk-cm-icon">
            <FontAwesomeIcon icon={css} className="icon"/>
          </div>
        </div>
    )
  }


}

export default Component;
