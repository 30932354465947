import {useState, useEffect, useRef} from 'react';
import { isEmpty } from './func';

const Component = ({className, placeholder, value, onChange, onValidate, showError, required, minLength, maxLength, disabled, decimalPlaces = 8, min, max}) => {
  const [error, setError] = useState(null);

  const validate = () => {
    if(isEmpty(value)){
      if(required){
        return 'Required.';
      }
      return null;
    }
    if(minLength && minLength > 0){
      if(value.length < minLength){
        return 'At least ' + minLength + ' chars.';
      }
    }
    if(maxLength && maxLength > 0){
      if(value.length > maxLength){
        return 'At most ' + maxLength + ' chars.';
      }
    }

   const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      if (min !== undefined && numericValue < min) {
        return `Value should be at least ${min}.`;
      }
      if (max !== undefined && numericValue > max) {
        return `Value should be at most ${max}.`;
      }
    }
    return null;
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [value])

  const handleChange = (inputValue) => {
    // Limit the number of decimal places
    if (inputValue.includes('.')) {
      const parts = inputValue.split('.');
      if (parts[1].length > decimalPlaces) {
        inputValue = parseFloat(inputValue).toFixed(decimalPlaces);
      }
    }

    // Call the original onChange prop with the modified value
    if (onChange) {
      onChange({ target: { value: inputValue } });
    }
  };

  return (
    <>
      <input type="number"
             pattern="[0-9]*" className={className}
             placeholder={placeholder} disabled={disabled}
             value={value}
             onChange={(e) => handleChange(e.target.value)}
             onWheel={(e) => e.target.blur()} 
             min={min}
            max={max}
             />
      {error && showError && (
        <span className="invalid">{error}</span>
      )}
    </>
  )
}

export default Component;
