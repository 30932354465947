import {useState, useEffect} from 'react';
import Layout from '../../../layouts/user';
import UserHead from '../../../components/user/head';
import ProfileNav from '../../../components/user/profileNav';
import { post } from '../../../libs/api';
import { Errors } from '../../../components/common/msg';
import { showDateTime } from '../../../libs/util';

function Page() {
  const [activities, setActivities] = useState([])
  const [errors, setErrors] = useState([])

  useEffect(() => {
    loadActivities();
  }, []);

  const loadActivities = async() => {
    const data = await post('/profile/activity');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }
    setActivities(data?.data);
  }

  return (
    <Layout menu="profile">  
      <UserHead title="Activity" description=""/>
      <ProfileNav active="activity"/>

      <div className="nk-block">
        <div className="nk-block-head">
            <div className="nk-block-between-md g-3">
                <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Last 20 activities in your account</h5>
                </div>
                <div className="nk-block-head-content">

                </div>
            </div>
        </div>

        <Errors errors={errors}/>

        <div className="card card-bordered">
          <table className="table table-ulogs">
            <thead className="thead-light">
            <tr>
              <th className="tb-col-os"><span className="overline-title">Browser <span className="d-sm-none">/ IP</span></span></th>
              <th className="tb-col-ip"><span className="overline-title">IP</span></th>
              <th className="tb-col-time"><span className="overline-title">Time</span></th>
              <th className="tb-col-action"><span className="overline-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
            {activities?.map((a, i) => (
              <tr key={i}>
                <td className="tb-col-os">{a.browser} on {a.platform}</td>
                <td className="tb-col-ip"><span className="sub-text">{a.ip}</span></td>
                <td className="tb-col-time">
                    {/* <span className="sub-text">17-09-2022  <span className="d-none d-sm-inline-block"> 07:29 AM</span></span> */}
                    <span className="sub-text">{showDateTime(a.session)}</span>
                </td>
                {/* <td className="tb-col-action">
                  <a href="#" className="link-cross mr-sm-n1" onClick={(e) => {e.preventDefault(); handleDelete(a.id)}}><em className="icon ni ni-cross"></em></a>
                </td> */}
              </tr>
            ))}
            </tbody>
          </table>
        </div>

      </div>

    </Layout>
  )
}

export default Page;
