import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import WithdrawForm from '../../components/wallet/withdrawForm';
import SecurityVerification from '../../components/common/securityVerification';
import { Errors, Successes } from '../../components/common/msg';
import NeedVerification from '../../components/common/needVerification';
import { post } from '../../libs/api';
import { getUser } from '../../libs/user';


function Page() {
  const [title, setTitle] = useState('Withdraw Coin');
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [whitelists, setWhitelists] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [withdraw, setWithdraw] = useState({});
  const [step1Submitting, setStep1Submitting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [fee, setFee] = useState(0.00);
  const navigate = useNavigate();

  const loadWhitelists = async() => {
    setLoading(true);
    // const user = await getUser();
    // if(user){
    //   setVerified(user.profileVerified);
    // }

    const data = await post('/wallet/whitelist/withdraw/list');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    } 
    let list = [];
    for(let item of data.data){
      if(item.status == 'VERIFIED' && item.address != null){
        list.push(item);
      }
    }
    setWhitelists(list);

    const walletData = await post('/wallet/balance');
    if(walletData?.status == 200){
      setWallets(walletData.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadWhitelists();
  }, [])

  const handleSetAmount = async (whitelist, wallet, amount)  => {
    //Validate Withdrawal requests
    setErrors([]);

    if (!amount || amount == 0.0) {
      setFee(0.0);
      return;
    }

    if(wallets && whitelist && amount) {
      const data = await post('/wallet/withdrawal/getfee', {
        wallet_id: wallet.wallet_id,
        whitelist_id: whitelist.whitelist_id,
        amount
      });
      if (data?.status != 200) {
        setSubmitting(false);
        if (data.error)
          setErrors([data.error]);
        return;
      }
      setFee(data.data.fee_rate);
    } else {
      setFee(0.0);
    }
  }

  const handleResetFee = async() => {
    setFee(0.0);
  }

  const handleStep1Submit = async (whitelist, wallet, amount) => {
    //Validate Withdrawal requests
    setErrors([]);

    const data = await post('/wallet/withdrawal/validate', {
      wallet_id: wallet.wallet_id,
      whitelist_id: whitelist.whitelist_id,
      amount
    });
    if(data?.status != 200){
      setSubmitting(false);
      setErrors([data.error]);
      return;
    }

    const params = {
      wallet_id: wallet.wallet_id, 
      whitelist_id: whitelist.whitelist_id, 
      amount, 
      fee
    }
    handleVerifySubmit(params)
  }
  
  const handleVerifySubmit = async (params) => {
    setSubmitting(true);
    setErrors([]);
    setSuccesses([]);

    const data = await post('/wallet/withdrawal/add', params);
    if(data?.status != 200){
      setSubmitting(false);
      setErrors([data.error]);
      return;
    }
    navigate('/portal/withdrawed');
  }

  return (
    <Layout>
      
    {!loading && !verified && (
      <NeedVerification/>
    )}

    {!loading && verified && (
    <div className="page-invest wide-xs m-auto" id="iv-step-container">
      <div className="nk-pps-apps">
        <div className="nk-pps-title text-center">
          <h3 className="title">{title}</h3>
          {/* <p className="caption-text">&nbsp;</p> */}
        </div>
        {errors.length > 0 && (
          <Errors errors={errors}/>
        )}
        {successes.length > 0 && (
          <Successes msgs={successes}/>
        )}

        {whitelists.length > 0  && wallets.length > 0 && (
          <WithdrawForm whitelists={whitelists} wallets={wallets} setWithdrawalAmount={handleSetAmount}  resetFee={handleResetFee} fee={fee} submitting={step1Submitting} onSubmit={handleStep1Submit} />
        )}

      </div>
    </div>
    )}
    </Layout>
  )
}

export default Page;
