import {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import { Errors, Successes } from '../common/msg';

import {SelectField, useValidation, NumberField, TextField} from '../form';
import CurrencySelect from '../form/currencySelect';
import {post, upload} from '../../libs/api';


const Component = ({whitelists, wallets, submitting, onSubmit}) => {

  const REFRESH_INTERVAL = 2;
  const CONFIRM_INTERVAL = 30; //sec

  const DECIMAL_USDC = '6'; //sec
  const DECIMAL_BTC = '8'; //sec
  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const fileRef3 = useRef(null);

  const [files, setFiles] = useState({
    file1: null,
    file2: null,
    file3: null
  });
  const [amount, setAmount] = useState('');
  const [fiatAmount, setFiatAmount] = useState('');
  const [extBankRefNo, setExtBankRefNo] = useState('');
  const [addressOptions, setAddressOptions] = useState([]);
  const [address, setAddress] = useState();
  const [whitelist, setWhitelist] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('USDT');
  const [errors, setErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const [countdown, setCountdown] = useState(REFRESH_INTERVAL);
  const [successes, setSuccesses] = useState([]);

  useEffect(() => {
    if(whitelists){
      let list = [];
      for(const white of whitelists){
        if(white.purpose != 'DEPOSIT'){ // only deposit address can be used
          continue;
        }
        list.push({
          value: white.whitelist_id, 
          label: white.note + ' - ' + white.address + ' - ' + white.currency + '(' + white.network + ')',
          ...white
        });
      }
      setAddressOptions(list);
    }
  }, [whitelists])

  useEffect(() => {
    setWhitelist(whitelist);
    setAmount('');
  }, [whitelist])

  useEffect(() => {
    if(whitelist){
      setSelectedCurrency( whitelist?.currency.toUpperCase());
    }
  }, [whitelist])


  const handleFileChange = (e, fileKey) => {
    setFiles(prevFiles => ({
      ...prevFiles,
      [fileKey]: e.target.files[0]
    }));
  };

  const findWalletByCurrency = () => {
    const currency = whitelist.currency.toUpperCase();
    if (currency === "BTC" || currency === "USD") {
      return wallets.find(x => x.currency.toUpperCase() === currency);
    } else {
      return wallets.find(x => x.currency.toUpperCase() === selectedCurrency.toUpperCase());
    }
  };
  
  const handleSubmit = async(e) => {
    setErrors([]);
    if(whitelist.currency !== "USD" && !isValid()){
      setShowError(true);
      return;
    }

    const submitWallet = findWalletByCurrency()
    if(!submitWallet){
      setErrors(['No wallet found.']);
      return;
    }

    let fileUploads = [];
    if (whitelist.currency === "USD") {
      for (const [key, file] of Object.entries(files)) {
        if (file === null || file.length === 0) {
          continue;
        }
        fileUploads.push(uploadFile(key, file));
      }
    }
  
    try {
      const results = await Promise.all(fileUploads);
  
      const [file1, file2, file3] = results.map(res => res.fileId);
      console.log("All file uploads complete", file1, file2, file3);
      const depositAmount = whitelist.currency === "USD" ? fiatAmount : amount;
      if (onSubmit) {
        onSubmit(whitelist, submitWallet, depositAmount, extBankRefNo, file1, file2, file3);
      }
    } catch (error) {
      setErrors(['Error uploading']);
      setErrors(prevErrors => [...prevErrors, 'Error uploading proof of ownership document']);
      return;
    }
  }

  const uploadFile = async (key, file) => {
    try {
      const data = await upload('/wallet/file/upload', file);
      console.log("upload result", data);
      if (data?.status !== 200) {
        throw new Error('Error uploading');
      }
      const fileId = data.data.file_id;
      console.log(`Uploaded file ID for ${key}: ${fileId}`);
      return { key, fileId };
    } catch (error) {
      throw error;
    }
  };

  ///simulate/deposit
  const simulateDeposit = async (ccy) => {
    if (process.env.REACT_APP_IS_SKIP_CUSTODIAN == 'true') {

      let params = {ccy: ccy.toUpperCase === 'BTC'? ccy : selectedCurrency};
      setErrors([]);
      setSuccesses([]);
      const data = await post('/wallet/simulate/deposit', params);
      if (data?.status != 200) {
        setErrors([data.error]);
        return;
      }

      setSuccesses(["Deposit process successfully"]);
    }
  }
  
  const handleCurrencyChange = (e) => {
      setSelectedCurrency(e.target.value)  
  };

  const onSelectAddress = (e)=>{
    setAddress(e.target.value);
    setWhitelist(e.target);
  }

  return (
    <>
    {errors.length > 0 && (
      <Errors errors={errors}/>
    )}
    {successes.length > 0 && (
      <Successes msgs={successes}/>
    )}
    <div className="form-validate is-alter mt-4 form-authentic">
      <div className="form-group">
        <label className="form-label" htmlFor="current-password">Whitelist Address  <span className="text-danger">*</span></label>
        <div className="form-control-wrap">
          <SelectField className="form-control" placeholder="Please select"
            required={true}
            showError={showError} onValidate={(valid) => setFieldValid('address', valid)}
            options={addressOptions}
            value={address}
            onChange={(e)=>onSelectAddress(e)}
          />
          {whitelist && whitelist?.currency !== "USD" && <Link to="/portal/wallet/whitelist/add" className="btn btn-primary mt-2">
            <span>Add Deposit Address</span></Link> 
          }         
        </div>
      </div>

     {whitelist && whitelist.currency !== "USD" && <div className="form-group">
        <label className="form-label" htmlFor="amount">Amount <span className="text-danger">*</span></label>
        <div className="form-control-wrap">

          <div className="form-info">
            { whitelist?.currency.toUpperCase() === "BTC" ? 
                "BTC" :        
                <CurrencySelect 
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                />
            }
          </div>

          <NumberField
              className="form-control form-control-lg form-control-number bg-white"
              placeholder="Enter Amount"
              value={amount} required={true} showError={showError}
              onValidate={(valid) => setFieldValid('amount', valid)}
              onChange={(e) => setAmount(e.target.value)}
              decimalPlaces={( whitelist?.currency.toUpperCase() == 'USDC' || whitelist?.currency.toUpperCase() == 'USDT')  ? DECIMAL_USDC : DECIMAL_BTC}
          />

        </div>
      </div>}

      {whitelist && whitelist?.currency === "USD" && <div >
      <div className="form-group">
            <label className="form-label" htmlFor="notes">Amount</label>
            <div className="form-control-wrap">
              <TextField className="form-control form-control-lg" placeholder="Enter Amount"
                         maxLength={190}
                         showError={showError} 
                         value={fiatAmount}
                         onChange={(e) => setFiatAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="notes">Reference Number</label>
            <div className="form-control-wrap">
              <TextField className="form-control form-control-lg" placeholder="Enter Reference Number"
                         maxLength={190}
                         showError={showError} 
                         value={extBankRefNo}
                         onChange={(e) => setExtBankRefNo(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
                  <label className="form-label" htmlFor="address">Attachments</label>
                  <div className="form-control-wrap mb-3">
                    <input type="file" ref={fileRef1} onChange={(e) => handleFileChange(e, 'file1')}/>
                  </div>
                  <div className="form-control-wrap mb-3">
                    <input type="file" ref={fileRef2} onChange={(e) => handleFileChange(e, 'file2')}/>
                  </div>
                  <div className="form-control-wrap mb-3">
                    <input type="file" ref={fileRef3} onChange={(e) => handleFileChange(e, 'file3')}/>
                  </div>
          </div>
        </div>}


      {whitelist && whitelist.currency !== "USD" && process.env.REACT_APP_IS_SKIP_CUSTODIAN == 'true' &&
        <div className="col-md-12 center">
          <a href="#" className="btn btn-lg btn-danger"  onClick={(e) => {e.preventDefault(); simulateDeposit(whitelist.currency)}} > Test Simulate Deposit </a>
        </div>
      }
    
      {whitelist && (
      <div className="form-note-group">
        <span className="form-note-alt iv-invest-fixed">
          <em>Warning: Please send your {whitelist.currency} coins only on the {whitelist.network} network with the correct address. Sending it on a wrong network will result in the permanent loss of funds.</em>
        </span>
      </div>
      )}

      <div className="nk-pps-field form-action mt-4">
        <div className="nk-pps-action">
          <button className="btn btn-lg btn-block btn-primary" onClick={handleSubmit}>
            <span>Submit Deposit Request</span>
            {submitting && (
              <span className="spinner-border spinner-border-sm" role="status"></span>
            )}
          </button>
        </div>
      </div>
    </div>    
    </>    
  )
}

export default Component;
