import {Link} from 'react-router-dom';

const Component = ({active}) => {
  const items = [
    {key: 'settings', 'text': 'Profile', 'link': '/portal/profile'},
    {key: 'security', 'text': 'Security', 'link': '/portal/profile/security'},
    // {key: 'verification', 'text': 'Verification', 'link': '/portal/profile/verification'},
    {key: 'activity', 'text': 'Activity', 'link': '/portal/profile/activity'},
  ]

  return (
    <ul className="nk-nav nav nav-tabs">
      {items.map((item, i) => (
        <li className={active == item.key ? 'nav-item active' : 'nav-item'} key={i}>
          <Link className="nav-link" to={item.link}>{item.text}</Link>
        </li>
      ))}
    </ul>    
  )
}

export default Component;
