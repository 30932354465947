import {useState, useEffect} from 'react';
import Layout from '../../layouts/user';
import { Successes } from '../../components/common/msg';

function Page() {
  const [successes, setSuccesses] = useState([]);

  useEffect(() => {
    setSuccesses(['The deposit request has been submitted.']);
  }, [])

  return (
    <Layout>      
      <div className="page-invest wide-xs m-auto" id="iv-step-container">
        <div className="nk-pps-apps">
          <div className="nk-pps-title text-center">
            <h3 className="title">Deposit Coin</h3>
          </div>

          {successes.length > 0 && (
            <Successes msgs={successes}/>
          )}

        </div>
      </div>
    </Layout>
  )
}

export default Page;
