import {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import NeedVerification from '../../components/common/needVerification';
import UserContext from '../../libs/context';
import { post } from '../../libs/api';
import { showPrice } from '../../libs/util';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [dashboard, setDashboard] = useState();

  const {currentUser} = useContext(UserContext);

  const loadDashboard = async () => {
    const data = await post('/dashboard/index');
    if(data?.status == 200){
      setDashboard(data.data);
    }

  }

  useEffect(() => {
    loadDashboard();
  }, [])

  return (
    <Layout menu="dashboard">  
      <UserHead sub="Welcome!" title={currentUser?.name} description="Here's a summary of your account.">
        <ul className="nk-block-tools gx-3">
          <li><a href="/portal/plans" className="btn btn-secondary"><span>Our Products</span> <em className="icon ni ni-arrow-long-right d-none d-lg-inline-block"></em></a></li>
        </ul>
      </UserHead>             

      {/* <div className="nk-block">
        {!loading && !verified && (
          <NeedVerification/>
        )}
      </div> */}

      <div className="nk-block">
        <div className="row g-gs">
          <div className="col-md-4">
            <div className="card card-full card-bordered card-wg on-bottom is-primary">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="nk-wgacc-title">Available Balance
                        <span className="sub-text">Indicative USD Value</span></h5>
                  </div>
                  <div className="card-tools">
                  </div>
                </div>
                <div className="card-amount mt-2 mb-1">
                  <span className="amount"> {showPrice(dashboard?.avail_balance, dashboard?.avail_balance_ccy)} </span>
                </div>
                <div className="card-stats">
                    <div className="card-stats-group g-2">
                        <div className="card-stats-data">
                            <div className="title fw-bold">
                                Investment Account
                            </div>
                            <div className="amount fw-bold">
                              {showPrice(dashboard?.invest_balance, dashboard?.invest_balance_ccy)} 
                            </div>
                        </div>
                    </div>
                    <div className="card-stats-ck sm">
                        
                    </div>
                </div>              
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-full card-bordered card-wg on-bottom is-base">
              <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title">
                        <h5 className="nk-wgacc-title">Total Deposit Amount
                            <span className="sub-text">Indicative USD Value</span></h5>
                    </div>
                    <div className="card-tools">
                    </div>
                </div>
                <div className="card-amount mt-2 mb-1">
                    <span className="amount">{showPrice(dashboard?.deposit_amt, dashboard?.deposit_amt_ccy)} </span>
                </div>
                <div className="card-stats">
                  <div className="card-stats-group g-2">
                    <div className="card-stats-data">
                      <div className="title fw-bold">
                          This Month
                      </div>
                      <div className="amount fw-bold">
                        {showPrice(dashboard?.mth_deposit_amt, dashboard?.mth_deposit_amt_ccy)} 
                      </div>
                    </div>
                  </div>
                  <div className="card-stats-ck sm">
                      
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-full card-bordered card-wg on-bottom is-gray">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="nk-wgacc-title">Total Withdrawal Amount
                        <span className="sub-text">Indicative USD Value</span></h5>
                  </div>
                  <div className="card-tools">
                  </div>
                </div>
                <div className="card-amount mt-2 mb-1">
                  <span className="amount">{showPrice(dashboard?.withdrawal_amt, dashboard?.withdrawal_amt_ccy)}</span>
                </div>
                <div className="card-stats">
                  <div className="card-stats-group g-2">
                    <div className="card-stats-data">
                      <div className="title fw-bold">
                            This Month
                      </div>
                      <div className="amount fw-bold">
                        {showPrice(dashboard?.mth_withdrawal_amt, dashboard?.mth_withdrawal_amt_ccy)}
                      </div>
                    </div>
                  </div>
                  <div className="card-stats-ck sm">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
