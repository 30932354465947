import { createContext, useState, useEffect } from "react";
import { isLogin, getAuthToken, setAuthToken, removeAuthtoken } from "./auth";
import { post } from "./api";
import Maintenance from "../pages/public/maintenance";
// import { getUser } from "./user";

const UserContext = createContext();

export const UserProvider = ({children}) => {

  const [currentUser, setCurrentUser] = useState();
  const [authStatus, setAuthStatus] = useState();
  const [maintMode, setMaintMode] = useState(true);
  const [notification, setNotification] = useState(null);
  const [manageUser, setManageUser] = useState();

  useEffect(() => {
    if(!currentUser){
      loadUser();
      return;
    }

    if(currentUser.status == 'APPROVED' || currentUser.status == 'VERIFIED'){
      setAuthStatus('VERIFIED');
    }
    else{
      setAuthStatus('AUTHED');
    }
  }, [currentUser]);

  const loadUser = async () => {
    const token = getAuthToken();
    loadUserWithToken(token);
  }

  const loadUserWithToken = async (token) => {
    if(!token){
      setAuthStatus('UNAUTHED');
      if(currentUser){
        setCurrentUser(null);
      }
      return;
    }

    const data = await post('/user/get', {}, false);
    if(data?.status != 200){
      return;
    }
    const user = data.data;
    setCurrentUser(user);

  }

  const login = async (token, expired) => {
    setAuthStatus(null);
    setAuthToken(token, expired);
    await loadUserWithToken(token);
  }

  const logout = () => {
    removeAuthtoken();
    loadUserWithToken(null);
  }

  return (
    <UserContext.Provider value={{currentUser, authStatus, login, logout, maintMode, setMaintMode, notification, setNotification}}>{children}</UserContext.Provider>
  )
}

export default UserContext;
