import {Link} from 'react-router-dom';
import { showDateTime, showPrice } from '../../libs/util';
import CurrencyIcon from '..//common/icon';

const Component = ({transaction, onShow}) => {

    const order = transaction.swap;

    return (
        <div className="nk-odr-item"> {/*is-cancelled*/}
            <div className="nk-odr-col">
                <div className="nk-odr-info">
                    <div className="nk-odr-badge">
                        {/* {!! tnx_type_icon($transaction, 'odr-icon') !!} */}
                        {/* *Icon* */}
                        <CurrencyIcon currency={'sw'}/>
                    </div>
                    <div className="nk-odr-data">
                        <div className="nk-odr-label">
                            <strong className="ellipsis">
                                Swap {order.swap_ccy} to {order.swap_to_ccy}
                            </strong>
                        </div>
                        <div className="nk-odr-meta">
                      <span className="date">
                          {transaction?.reference_id}
                      </span>
                        </div>
                        <div className="nk-odr-meta">
                      <span className="date">
                        {showDateTime(order?.value_datetime)}
                        </span>

                            <span className={(order?.order_status != "COMPLETED") ? "status text-dark": "status text-success"}>
                                {order?.order_status}
                            </span>

                        </div>
                    </div>
                </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
                <div className="nk-odr-amount">
                    <div className="number-md text-s text-danger">
                        {/* {{ ($transaction->calc == TransactionCalcType::CREDIT) ? '+' : '-' }} {{ ($transaction->calc == TransactionCalcType::CREDIT) ? amount_z($transaction->amount, $base_currency, ['dp' => 'calc']): amount_z($transaction->total, $base_currency, ['dp' => 'calc']) }} */}
                        -{showPrice(order?.swap_amount, order?.swap_ccy)}
                    </div>
                    <div className="number-md text-s text-dark">
                        {/* {{ ($transaction->calc == TransactionCalcType::CREDIT) ? '+' : '-' }} {{ ($transaction->calc == TransactionCalcType::CREDIT) ? amount_z($transaction->amount, $base_currency, ['dp' => 'calc']): amount_z($transaction->total, $base_currency, ['dp' => 'calc']) }} */}
                        {showPrice(order?.swap_to_amount, order?.swap_to_ccy)}
                    </div>
                </div>
            </div>
            <div className="nk-odr-col nk-odr-col-action">
                <div className="nk-odr-action">
                    <a className="tnx-details" href="#" data-toggle="modal" data-target="#txn-details" onClick={(e) => {e.preventDefault(); onShow()}}><em className="icon ni ni-forward-ios"></em></a>
                </div>
            </div>
        </div>

    )
}

export default Component;
