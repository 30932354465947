import {useState, useEffect, useRef} from 'react';
import { isEmpty, isValidEmail } from './func';

const Component = ({className, placeholder, value, onChange, onValidate, showError, required, disabled}) => {
  const [error, setError] = useState(null);

  const validate = () => {
    if(isEmpty(value)){
      if(required){
        return 'Required.';
      }
      return null;
    }
    if(!isValidEmail(value)){
      return 'Enter a valid email.';
    }
    return null;
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [value])

  return (
    <>
      <input type="text" className={className}
        placeholder={placeholder}
        value={value} disabled={disabled}
        onChange={onChange}/>
      {error && showError && (
        <span className="invalid">{error}</span>
      )}
    </>
  )
}

export default Component;
