import {useState, useEffect, useRef} from 'react';
import { isEmpty } from './func';

const Component = ({className, children, checked, onChange, onValidate, showError, required, requiredMsg, disabled}) => {
  const [error, setError] = useState(null);

  const validate = () => {
    if(!checked){
      if(required){
        return requiredMsg ? requiredMsg : 'Required.';
      }
      return null;
    }
    return null;
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [checked])

  const handleClick = (e) => {
    if(onChange){
      onChange(!checked);
    }
  }

  const handleChange = (e) => {

  }

  return (
    <div className="custom-control custom-checkbox" onClick={handleClick}>
      <input type="checkbox" className={className}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}/>
      {error && showError && (
        <span className="invalid">{error}</span>
      )}
      <label className="custom-control-label">{children}</label>
    </div>
  )
}

export default Component;
