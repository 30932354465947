import {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/auth';
import countries from '../../config/countries';
import { UnsupportedCountries } from '../../config/constants';
import { Errors } from '../../components/common/msg';
import { post, getApiUrl } from '../../libs/api';

import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';

import { EmailField, PasswordField, TextField, SelectField, DateField, CheckField, useValidation } from '../../components/form';
import firebaseConfig from '../../config/firebase';

function Page() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [processing, setProcessing] = useState(false);
  const [country, setCountry] = useState('');
  const [accept, setAccept] = useState(false);
  const [tab, setTab] = useState(0);
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [errors, setErrors] = useState([]);

  const form = useRef(null);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const handleRegister = async (e) => {
    // e.preventDefault();
    if(!isValid()){
      setShowError(true);
      return;
    }

    // if(form.current.checkValidity() === false){
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    // e.preventDefault();
    if(processing){
      return;
    }

    setProcessing(true);
    try{
      const cred = await createUserWithEmailAndPassword(auth, email, password);
      const uid = cred.user.uid;
      const idToken = await cred.user.getIdToken();
      setToken(idToken);
      
      const ret = await updateProfile(cred.user, {displayName: name});

      // send token to backend
      const data = await post('/auth/register', {token: idToken, uid, name, email, phone, country});
      if(parseInt(data?.status) != 200){
        setErrors([data.error]);
        return;
      }
      setTab(1);
    }
    catch(err){
      console.log('err', err);
      if(err.code == 'auth/email-already-in-use'){
        setErrors(['The email address has already been registered.']);
      }
      else{
        setErrors([err.message]);
      }
    }
    finally{
      setProcessing(false);
    }
  }

  return (
    <Layout>
      {tab == 0 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Create an Account</h4>
            <div className="nk-block-des mt-2">
              <p>Sign up with your email and get started with your free account.</p>
            </div>
            <Errors errors={errors}/>
          </div>
        </div>

        <div className="form-validate is-alter">
        <div className="form-group">
            <label className="form-label" htmlFor="full-name">User Name<span className="text-danger"> &nbsp;*</span></label>
            <div className="form-control-wrap">
              <TextField className="form-control form-control-lg" placeholder="Create a user name for your account"
                    value={name} required={true} minLength={3} showError={showError}
                    onValidate={(valid) => setFieldValid('name', valid)}
                    onChange={(e) => setName(e.target.value)}/>                
            </div>
        </div>
        <div className="form-group">
            <label className="form-label" htmlFor="email-address">Email Address<span className="text-danger"> &nbsp;*</span></label>
            <div className="form-control-wrap">
              <EmailField className="form-control form-control-lg" placeholder="Enter your email address"
                    value={email} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('email', valid)}
                    onChange={(e) => setEmail(e.target.value)}/>                
            </div>
        </div>
        <div className="form-group">
            <label className="form-label" htmlFor="passcode">Password<span className="text-danger"> &nbsp;*</span></label>
            <div className="form-control-wrap">
              <PasswordField className="form-control form-control-lg" placeholder="Enter your passcode"
                      value={password} required={true} minLength={12} showError={showError} test={true}
                      onValidate={(valid) => setFieldValid('password', valid)}
                      onChange={(e) => setPassword(e.target.value)}/>
            </div>
          <div className="form-control-wrap small">
            <i>* 12 characters or more, containing a digit, uppercase letter, and symbol</i>
          </div>
        </div>

        <div className="form-group">
            {/* <div className="custom-control custom-control-xs custom-checkbox"> */}
                <CheckField className="custom-control-input" requiredMsg=" You should accept our terms." 
                  required={true} showError={showError}
                  onValidate={(valid) => setFieldValid('accept', valid)}
                  checked={accept}
                  onChange={(checked) => setAccept(checked)}
                >
                  I have read and agree to the <a href="https://nodeam.com/terms-and-conditions/" target="_blank">Terms &amp; Condition</a>
                </CheckField>
                
            {/* </div> */}
        </div>
                          
        <div className="form-group">
          <button className="btn btn-lg btn-primary btn-block" 
            disabled={processing} onClick={handleRegister}>Register</button>
        </div>
        </div>

        <div className="form-note-s2 text-center pt-4">
          <span>Already have an account? <Link to="/login"><strong>Sign in instead</strong></Link></span>
        </div>
        </>
      )}

      {tab == 1 && (
        <>
          <div className="nk-block-head text-center">
            <h4 className="nk-block-title">Registration completed successfully.</h4>
          </div>
          <div className="nk-block-content text-center">
              <p><strong>Thank you for signing up!</strong></p>
              <p>We just need you to verify your email address. Please check your inbox including spam folder for a verification mail.</p>
              <div className="gap gap-md"></div>
              <Link to="/login" className="btn btn-lg btn-block btn-primary">Return to Login</Link>             
          </div>
        </>
      )}

    </Layout>
  )
}

export default Page;
