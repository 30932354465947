import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import {post} from '../../libs/api';
import SecretQnAForm from '../../components/user/secretQnAForm';
import { Errors } from '../../components/common/msg';

function Page() {
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState(0);
  const [fields, setFields] = useState(null);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const updateSqna = async(updated) => {
    setErrors([]);
    const data = await post('/profile/sqna/update', updated);
    if(data?.status == 200){
      setFields(updated);

      if (data.data.user.role == 'agent')
        navigate('/agent');
      else
        navigate('/portal')
    }
    else{
      setErrors([data.error]);
    }
  }


  const loadSQnA = async() => {
    const data = await post('/profile/sqna/get');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }

    if(data.data.hasSecret == true){

      if (data.data.user.role == 'agent')
        navigate('/agent');
      else
        navigate('/portal')

      return;
    }
    setFields(data.data.fields);
    setTab(1);
  }

  useEffect(() => {
    loadSQnA();
  }, [])

  return (
    <Layout>
      <div className="nk-block-head">
        <div className="nk-block-head-content text-center">
          <h4 className="nk-block-title">Secret Question and Answers</h4>
        </div>
      </div>
      <Errors errors={errors}/>

      {tab == 1 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <div className="nk-block-des">
                <p>These questions and answers will be used for future verifications.</p>
            </div>
          </div>
        </div>
          <SecretQnAForm fields={fields} onUpdate={updateSqna}/>
        </>

      )}
    </Layout>
  )
}

export default Page;
