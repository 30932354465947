import {useState, useEffect} from 'react';

export default () => {
  const [validation, setValidation] = useState({});
  const setFieldValid = (field, valid) => {
    validation[field] = valid;
    setValidation({...validation});
  }

  const isValid = (f = null) => {
    if(f){
      return validation[f];
    }
    for(const field in validation){
      if(!validation[field]){
        return false;
      }
    }
    return true;
  }

  return [setFieldValid, isValid];
};
