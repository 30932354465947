
export const Errors = ({errors}) => {
  return (
    <>
      {errors?.map((e, i) => {
        return <p className="alert alert-danger p-1 mb-2" key={i}>{e}</p>
      })}
    </>
  )
}

export const Successes = ({msgs}) => {
  return (
    <>
      {msgs?.map((e, i) => {
        return <p className="alert alert-primary p-1 mb-2" key={i}>{e}</p>
      })}
    </>
  )
}

export const Notices = ({msgs}) => {
  return (
      <>
        {msgs?.map((e, i) => {
          return <p className="alert alert-notice p-1 mb-2" key={i}> <span className="icon ni ni-alert"></span> {e} </p>
        })}
      </>
  )
}
