import {Link} from 'react-router-dom';
import InvestmentRow from './investmentRow';
import DepositRow from './depositRow';
import WithdrawalRow from './withdrawalRow';
import RedemptiontRow from "./redemptiontRow";
import SwapRow from "./swapRow";

const Component = ({transaction, onShow}) => {

  return (
    <>
      {transaction.investment && (
        <InvestmentRow transaction={transaction} onShow={onShow}/>
      )}
      {transaction.deposit && (
        <DepositRow transaction={transaction} onShow={onShow}/>
      )}
      {transaction.withdrawal && (
        <WithdrawalRow transaction={transaction} onShow={onShow}/>
      )}
      {transaction.redemption && (
        <RedemptiontRow transaction={transaction} onShow={onShow}/>
      )}
      {transaction.swap && (
        <SwapRow transaction={transaction} onShow={onShow}/>
      )}
    </>
  )
}

export default Component;
