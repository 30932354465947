import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../layouts/user";
import UserHead from "../../components/user/head";
import WalletNav from "../../components/wallet/nav";
import NeedVerification from "../../components/common/needVerification";
import { post } from "../../libs/api";
import { showAmount } from "../../libs/util";
import { getUser } from '../../libs/user';
import {Errors, Successes} from '../../components/common/msg';

function Page() {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [clientId, setClientId] = useState("");
  const [depositAddress, setDepositAddress] = useState([]);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);


  const filterAccounts = (list) => {
    let filtered = [];
    for(const acc of list){
      filtered.push(acc);
    }
    return filtered.reverse();
  };


  const sendDepositEmail = async () => {

    setLoading(true);
    setSuccesses([]);
    setErrors([]);
    const data = await post('/wallet/deposit_instruction');
    setLoading(false);
    if(data?.status != 200){
      setErrors([data?.error]);
      return;
    }

    setSuccesses(['Email has been sent successfully.']);

  };

  const sendFiatDepositEmail = async () => {

    setLoading(true);
    setSuccesses([]);
    setErrors([]);

    const data = await post('/wallet/fiat_deposit_instruction');
    setLoading(false);
    if(data?.status != 200){
      setErrors([data?.error]);
      return;
    }

    setSuccesses(['Fiat Deposit Email has been sent successfully.']);

  };

  const loadAccounts = async () => {
    setLoading(true);
    const userData = await getUser();
    setClientId(userData.manage_user.clientId);

    const data = await post("/wallet/balance", {});
    if (data.status == 200) {
      setAccounts(filterAccounts(data.data));
    }
    setLoading(false);
  };

  const loadDepositAddress = async () => {
    setLoading(true);
    const response = await post("/wallet/deposit/address", {});
    if (response.status == 200) {
      const filteredAddress = response.data.filter(add => add.currency !== 'USD')
      setDepositAddress(filteredAddress.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAccounts();
    loadDepositAddress();

    setSuccesses([]);
  }, [])

  return (
    <Layout>
      <UserHead sub="" title="Wallet Balances" description="">
        <ul className="nk-block-tools gx-3">
          <li>
            <Link to="/portal/deposit" className="btn btn-primary">
              <span>Deposit</span>{" "}
              <em className="icon ni ni-arrow-long-right"></em>
            </Link>
          </li>
        </ul>
      </UserHead>

      <Successes msgs={successes}/>
      <Errors errors={errors}/>

      <WalletNav active="balance" />

      <div className="nk-block nk-block-xs">
        {!loading && !verified && <NeedVerification />}

        {!loading && verified && (
          <>
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h6 className="nk-block-title">Available Coins</h6>
                </div>
              </div>
            </div>
            <div className="card card-bordered">
              <table className="table table-ulogs">
                <thead className="thead-light">
                  <tr>
                    <th className="tb-col-os">
                      <span className="overline-title">Coin</span>
                    </th>
                    <th className="tb-col-ip">
                      <span className="overline-title">Total</span>
                    </th>
                    <th className="tb-col-time">
                      <span className="overline-title">Available</span>
                    </th>
                    <th className="tb-col-time">
                      <span className="overline-title">Hold</span>
                    </th>
                    <th className="tb-col-time">
                      <span className="overline-title">In Order</span>
                    </th>
                    <th className="tb-col-action">
                      <span className="overline-title">&nbsp;</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((a, i) => (
                    <tr key={i}>
                      <td className="tb-col-os">
                        <span className="sub-text">{a.currency}</span>
                      </td>
                      <td className="tb-col-ip">
                        <span className="sub-text">
                          {showAmount(
                            a.main_balance + a.hold_balance + a.invest_balance
                          )}
                        </span>
                      </td>
                      <td className="tb-col-ip">
                        <span className="sub-text">
                          {showAmount(a.main_balance)}
                        </span>
                      </td>
                      <td className="tb-col-ip">
                        <span className="sub-text">
                          {showAmount(a.hold_balance)}
                        </span>
                      </td>
                      <td className="tb-col-ip">
                        <span className="sub-text">
                          {showAmount(a.invest_balance)}
                        </span>
                      </td>
                      <td className="tb-col-action">
                    { a.currency === "USD"   ? 
                    <a
                      href="#"
                      className=
                        "btn btn-sm btn-primary" 
                      onClick={sendFiatDepositEmail}
                    >
                      <span>Send US$ Deposit Instruction</span>
                    </a> : 
                    <Link
                          to="/portal/deposit"
                          className="btn btn-sm btn-primary"
                        >
                          Deposit
                        </Link>
                        }
                    
                        &nbsp;{" "}
                        {a.currency !== "USD" && (
                          <Link
                            to="/portal/withdraw"
                            className="btn btn-sm btn-primary"
                          >
                            Withdraw
                          </Link>
                        )}
                        {/*&nbsp; <Link to={"/portal/swap/"+a.currency} className="btn btn-sm btn-primary">Swap</Link>*/}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {
          // First Fragment open
          depositAddress.length > 0 && (
            <>
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h6 className="nk-block-title">Deposit Address</h6>
                  </div>
                </div>
              </div>
              <div className="card card-bordered">
                <table className="table table-ulogs">
                  <thead className="thead-light">
                    <tr>
                      <th className="tb-col-os">
                        <span className="overline-title">Coin</span>
                      </th>
                      <th className="tb-col-ip">
                        <span className="overline-title">Address</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositAddress.map((address, i) => (
                      <tr key={i}>
                        <td className="tb-col-os">
                          <span className="sub-text">{address.currency}</span>
                        </td>
                        <td className="tb-col-ip">
                          <span className="sub-text">{address.address}</span>
                        </td>
                      </tr>
                    ))}
                    
                    
                  </tbody>
                </table>
              </div>
              <div style={{"margin": '10px 0'}}>
                <a
                      href="#"
                      className=
                        "btn btn-sm btn-primary"
                      onClick={sendDepositEmail}
                    >
                      <span>Send Deposit Proxy Address</span>
                    </a>
                </div>
            </> //the Fragment Close here
          )
        }
      </div>
    </Layout>
  );
}

export default Page;
