import { useState } from "react"
import {post} from '../../libs/api';
import { Errors, Successes } from '../common/msg'; 

const Component = ({uid, token, email}) => {
  const [countdown, setCountdown] = useState(0);
  const [errors, setErrors] = useState([]);

  const handleResend = async () => {
    try{
      const data = await post('/auth/resend', {uid, token})
      if(data?.status != 200){
        console.log('resend error', data?.error);
      }

      setCountdown(60);
      const timer = setTimeout(() => {
        if(countdown > 0){
          setCountdown(countdown - 1);
        }
        else{
          clearTimeout(timer);
        }
      }, 1000)
    }
    catch(err){
      setErrors(err.message);
    }
  }

  return (
    <>
      <div className="nk-block-head text-center">
          <h4 className="nk-block-title">Verify your email address</h4>
      </div>
      <div className="nk-block-content text-center">
        <Errors errors={errors}/>

        <p>Your email address <strong>{email}</strong> has not been verified yet! In order to start using your account, you need to confirm your email address first.</p>
        <p>If you did not receive the email, click the button to resend.</p>

        <div className="form-group">                  
          <button type="button" className="btn btn-block btn-primary" onClick={handleResend} disabled={countdown > 0}>
            Resend Email {countdown > 0 && <>({countdown}s)</>}</button>
        </div>
        <div className="form-note">
          For account security, we required to verified your email address.
        </div>

          {/* <div className="divider"></div>
          <p>If you registered with wrong email address, update it now.</p>

          <form action="#" method="post" className="form-validate is-alter">
              <div className="form-group">
                  <div className="form-control-wrap">
                      <input type="email" name="email" className="form-control" placeholder="{{ $email }}" data-msg-email="{{ __('Enter a valid email.') }}" data-msg-required="{{ __('Required.') }}" required/>
                  </div>
              </div>
              <div className="form-group mb-n3">
                  <ul className="btn-group-vertical align-center gy-3">
                      <li className="w-100"><button className="btn btn-block btn-light">Update Email Address</button></li>
                      <li><a className="link link-primary" href="{{ route('auth.logout') }}" onClick="event.preventDefault(); document.getElementById('quick-logout').submit();">Sign Out</a></li>
                  </ul>
              </div>
          </form> */}
      </div>    
    </>
  )
}

export default Component;
