import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import InvestedRow from './investedRow';
import { post } from '../../libs/api';
import { Errors } from '../common/msg';
import ReactPaginate from 'react-paginate';

import { SelectField } from '../form';

const Component = () => {

  const [title, setTitle] = useState('');
  const [active, setActive] = useState('ACTIVE');
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [plans, setPlans] = useState(null);
  const [planTotal, setPlanTotal] = useState(0);
  const [errors, setErrors] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortField, setSortField] = useState('p.create_time');

  const navs = [
    {key: 'ACTIVE', 'text': 'Active', 'title': 'Active Investments'},
    {key: 'PENDING', 'text': 'Pending', 'title' : 'Pending Investments'},
    {key: 'COMPLETED', 'text': 'Completed', 'title': 'Completed Investments'}
  ]

  const sortOptions = [
    {value: "p.create_time", label: "Invest Date"},
    {value: "p.maturity_datetime", label: "Expiry Date"},
    {value: "p.reference_number", label: "Reference No"},
  ]

  const handleNav = (e, nav) => {
    e.preventDefault();
    setActive(nav);
    setPageOffset(0);
  }

  const loadPlans = async(type) => {
    // set title
    for(const item of navs){
      if(item.key == type){
        setTitle(item.title);
        break;
      }
    }

    setLoading(true);
    setLoaded(false);
    setPlans(null);
    setErrors([]);
    const result = await post('/investment/order/list', {type, pageNum:pageOffset+1, pageSize: pageSize, sortField, sortOrder:"desc"});
    setLoading(false);
    if(result?.status != 200){
      setErrors([result.error]);
      return;
    }
    setLoaded(true);
    setPlans(result.data);
    setPlanTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
  } 

  const handlePageChange = async(event) => {
    setPageOffset(event.selected);
  }

  useEffect(() => {
    loadPlans(active);
  }, [active, pageOffset, sortField]);

  return (
    <>
      <ul className="nk-nav nav nav-tabs">
        {navs.map((item, i) => (
          <li className={active == item.key ? 'nav-item active' : 'nav-item'} key={i}>
            <a className="nav-link" href="#" onClick={(e) => handleNav(e, item.key)}>{item.text}</a>
          </li>
        ))}
      </ul> 

      <div className="nk-block nk-block-xs">
        {errors?.length > 0 && (
          <Errors errors={errors}/>
        )}

        {loading && (
          <div className="nk-odr-list is-stretch card card-bordered ">
            <div className="nk-odr-item">
              <div className="nk-odr-col">Loading...</div>
            </div>
          </div>
        )}

        {loaded && (
          <>
          {plans?.length > 0 && (
            <div className="row mb-2">
              <div className="col-md-6 col-xs-12">
              <h6 className="nk-block-title float-left">{title} ({planTotal})</h6>
              </div>
              <div className="col-md-3 col-xs-4 text-right">
              Sort by:
              </div>
              <div className="col-md-3 col-xs-8">
              <SelectField className="form-control" placeholder="Please select"                    
                options={sortOptions}
                value={sortField}
                onChange={(e) => setSortField(e.target.value)}
              /> 
              </div>
            </div>                  
          )}

          <div className="nk-odr-list is-stretch card card-bordered mb-2 ">
            {(!plans || plans?.length == 0) ? (
              <div className="nk-odr-item">
                <div className="nk-odr-col">No investments found!</div>
              </div>
            ) : plans.map((t, i) => (
                <InvestedRow plan={t} key={i}/>
              )
            )}          
          </div>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={pageOffset}
          />
          </>
        )}
      </div>
    </>
  )
}

export default Component;
