import {Link} from 'react-router-dom';
import CurrencyIcon from '..//common/icon';
import { showDateTime, showPrice } from '../../libs/util';

const Component = ({transaction, referenceId}) => {
  return (
      <>
        <div className="dark-text">TXN ID #{referenceId}</div>
        <div className="dark-text">Swap {transaction.swap_ccy} to {transaction.swap_to_ccy}</div>
        <div className="nk-odr-item p-0 pt-1 pb-2">
          <div className="nk-odr-col">
            <div className="nk-odr-info">
              <div className="nk-odr-badge">
                <CurrencyIcon currency={'sw'}/>
              </div>
              <div className="nk-odr-data">
                <div className="nk-odr-label ">
                    <div className="text-danger">-{showPrice(transaction.swap_amount, transaction.swap_ccy)} </div>
                    &nbsp; to &nbsp;
                    <div className="text-dark"> {showPrice(transaction.swap_to_amount, transaction.swap_to_ccy)}</div>

                </div>
                <div className="nk-odr-meta">{showDateTime(transaction.value_datetime)}</div>
              </div>
            </div>
          </div>
      <div className="nk-odr-col nk-odr-col-amount text-right">{transaction.order_status}</div>
        </div>

        <div className="mt-1 dark-text">SWAP DETAILS</div>
          <div className="row mt-1">
              <div className="col-6">
                  <span className="lead-text">Swap Amount</span>
                  <span className="sub-text text-danger">-{showPrice(transaction.swap_amount, transaction.swap_ccy)}</span>
              </div>
              <div className="col-6">
                  <span className="lead-text">Final Amount</span>
                  <span className="sub-text text-dark">{showPrice(transaction.swap_to_amount, transaction.swap_to_ccy)}</span>
              </div>
          </div>

        <div className="row mt-1">
          <div className="col-6">
        <span className="lead-text">Currency Pair</span>
        <span className="sub-text text-dark">{transaction.base_ccy}/{transaction.counter_ccy}</span>
          </div>
          <div className="col-6">
            <span className="lead-text">Swap Rate</span>
              <span className="sub-text text-dark">{showPrice( ((transaction.dir == 'BUY') ? 1/transaction.swap_rate: transaction.swap_rate), transaction.swap_ccy)}</span>
          </div>
        </div>

        <div className="mt-2 dark-text border-top pt-1">TIMELINE</div>
        <div className="row mt-1">
          <div className="col-6"><span className="lead-text">Swapped On</span><span className="sub-text text-dark">{showDateTime(transaction.value_datetime)}</span></div>

        </div>
      </>
  )
}

export default Component;
