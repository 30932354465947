import {useState, useEffect, useRef} from 'react';
import countries from '../../config/countries';
// import Select from '../../components/common/select';
import {toDate, fromDate} from '../../libs/util';
import {AccType, UnsupportedCountries, UnsupportedNationalities} from '../../config/constants';

import {SelectField, DateField, TextField, useValidation} from '../../components/form';


const Component = ({uData, fields, onUpdate}) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [dob, setDob] = useState(null);
    const [accountType, setAccountType] = useState('');
    const [countryResidence, setCountryResidence] = useState('');
    const [countryBirth, setCountryBirth] = useState('');
    const [nationality, setNationality] = useState('');

    const [showError, setShowError] = useState(false);
    const [setFieldValid, isValid] = useValidation();

    useEffect(() => {
        if (fields) {
            setName(fields.full_name);
            setAddress(fields.address);
            setDob(toDate(fields.dob));
            setCountryResidence(fields.country);
            setAccountType(fields.type);
            setCountryBirth(fields.country_birth);
            setNationality(fields.nationality);
        }

        if (uData.role == 'agent')
            setAccountType("Agent");

    }, [fields])

    const handleSubmit = async (e) => {
        if (!isValid()) {
            setShowError(true);
            return;
        }

        // submit form
        if (onUpdate) {
            const updated = {...fields}
            updated.full_name = name;
            updated.address = address;
            updated.dob = fromDate(dob);
            updated.country = countryResidence;
            updated.type = accountType;
            updated.country_birth = countryBirth;
            updated.nationality = nationality;
            onUpdate(updated);
        }
    }

    return (
        <div className="form-validate is-alter">
            <div className="row gy-4">

                {uData.role != 'agent' && (

                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="full-name">Account Type<span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                                <SelectField className="form-control form-control-lg"
                                             placeholder="Please select an account type"
                                             required={true} showError={showError}
                                             onValidate={(valid) => setFieldValid('accountType', valid)}
                                             options={AccType}
                                             value={accountType}
                                             onChange={(e) => setAccountType(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                )}

                {uData.role == 'agent' && (

                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="full-name">Account Type<span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap text-gray">
                                 Managed Agent
                            </div>
                        </div>
                    </div>

                )}


                {accountType == 'Corporate' && (
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="full-name">Name of Entity<span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                                <TextField className="form-control form-control-lg" placeholder="Enter your entity name"
                                           required={true} showError={showError}
                                           onValidate={(valid) => setFieldValid('name', valid)} maxLength={190}
                                           value={name}
                                           onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {accountType == 'Corporate' && (
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="full-name">Registered Domicile <span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                                <SelectField className="form-control form-control-lg" placeholder="Please select a country"
                                             required={true} showError={showError}
                                             onValidate={(valid) => setFieldValid('nationality', valid)}
                                             unsupportedOptions={UnsupportedNationalities}
                                             unsupportedOptionMsg="Selected Domicile are not supported."
                                             options={countries}
                                             value={nationality}
                                             onChange={(e) => setNationality(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {accountType == 'Corporate' && (
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="full-name">Country of Operation <span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                                <SelectField className="form-control form-control-lg" placeholder="Please select a country"
                                             required={true} showError={showError}
                                             onValidate={(valid) => setFieldValid('countryResidence', valid)}
                                             unsupportedOptions={UnsupportedCountries}
                                             unsupportedOptionMsg="Selected country are not supported."
                                             options={countries}
                                             value={countryResidence}
                                             onChange={(e) => setCountryResidence(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}


                {(accountType == 'Individual' || accountType == 'Agent') && (
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">User Name <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <TextField className="form-control form-control-lg" placeholder="Enter your user name"
                                       required={true} showError={showError}
                                       onValidate={(valid) => setFieldValid('name', valid)} maxLength={190}
                                       value={name}
                                       onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                )}
                {(accountType == 'Individual' || accountType == 'Agent') && (
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Date of Birth <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <DateField className="form-control form-control-lg" placeholder="Enter your date of birth"
                                       required={true} showError={showError}
                                       onValidate={(valid) => setFieldValid('dob', valid)} maxLength={190}
                                       minAge={21} minAgeMsg="You must be at least 21 years old."
                                       value={dob}
                                       onChange={(date) => setDob(date)}
                            />
                        </div>
                    </div>
                </div>
                )}
                {(accountType == 'Individual' || accountType == 'Agent') && (
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Nationality <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <SelectField className="form-control form-control-lg" placeholder="Please select a country"
                                         required={true} showError={showError}
                                         onValidate={(valid) => setFieldValid('nationality', valid)}
                                         unsupportedOptions={UnsupportedNationalities}
                                         unsupportedOptionMsg="Selected nationality is currently not supported."
                                         options={countries}
                                         value={nationality}
                                         onChange={(e) => setNationality(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                )}
                {(accountType == 'Individual' || accountType == 'Agent') && (
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Country of Residence <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <SelectField className="form-control form-control-lg" placeholder="Please select a country"
                                         required={true} showError={showError}
                                         onValidate={(valid) => setFieldValid('countryResidence', valid)}
                                         unsupportedOptions={UnsupportedCountries}
                                         unsupportedOptionMsg="Residences of this country are not supported."
                                         options={countries}
                                         value={countryResidence}
                                         onChange={(e) => setCountryResidence(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                )}

                <div className="col-md-12">
                    <div className="form-group">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-2 ">
                            <li>
                                <button className="btn btn-lg btn-primary" onClick={handleSubmit}>Save and Continue</button>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Component;