import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Layout from '../../layouts/auth';

import { EmailField, PasswordField, useValidation } from '../../components/form';
import { Errors } from '../../components/common/msg';

import { post } from '../../libs/api';
import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, updatePassword } from 'firebase/auth';
import firebaseConfig from '../../config/firebase';

function Page() {
  const {code} = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState([]);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    fetchEmail();
  }, [])

  const fetchEmail = async () => {
    try{
      // send token to backend
      const data = await post('/auth/verifyCode', {token: code});
      if(data?.status != 200){
        setErrors([data.error]);
        return;
      }
      setEmail(data.data.email);
    }
    catch(err){
      setErrors([err.message]);
    }
  }

  const handleSubmit = async () => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    setErrors([]);

    if(password != password2){
      setErrors(['The two passwords do not match']);
      return;
    }

    try {
      // send token to backend
      const data = await post('/auth/reset', {token: code, password});
      if(data?.status != 200){
        setErrors([data.error]);
        return;
      }
      setTab(1);
    }
    catch(err){
      setErrors([err.message]);
    }
  }
  
  return (
    <Layout>
      {tab == 0 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Reset Password</h4>            
            <Errors errors={errors}/>
          </div>
        </div>

        <div className="form-validate is-alter">
          <div className="form-group">
            <div className="form-label-group">
                <label className="form-label" htmlFor="user-email">Email<span className="text-danger"> &nbsp;*</span></label>
            </div>
            <div className="form-control-wrap">
              <EmailField className="form-control form-control-lg" placeholder="Enter your email address"
                      value={email} required={true} showError={showError} disabled={true}
                      onValidate={(valid) => setFieldValid('email', valid)}
                      onChange={(e) => setEmail(e.target.value)}/>
            </div>
          </div>

          <div className="form-group">
            <div className="form-label-group">
                <label className="form-label" htmlFor="user-email">Password<span className="text-danger"> &nbsp;*</span></label>
            </div>
            <div className="form-control-wrap">
              <PasswordField className="form-control form-control-lg" placeholder="Enter your passcode"
                      value={password} required={true} minLength={8} showError={showError} test={true}
                      onValidate={(valid) => setFieldValid('password', valid)}
                      onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <div className="form-control-wrap small">
              <i>* 12 characters or more, containing a digit, uppercase letter, and symbol</i>
            </div>
          </div>

          <div className="form-group">
            <div className="form-label-group">
                <label className="form-label" htmlFor="user-email">Confirm Password<span className="text-danger"> &nbsp;*</span></label>
            </div>
            <div className="form-control-wrap">
              <PasswordField className="form-control form-control-lg" placeholder="Retype your passcode"
                      value={password2} required={true} minLength={8} showError={showError}  test={true}
                      onValidate={(valid) => setFieldValid('password2', valid)}
                      onChange={(e) => setPassword2(e.target.value)}/>
            </div>
          </div>

          <div className="form-group">
            <input type="hidden" id="recaptcha" value="" name="recaptcha"/>
            <button className="btn btn-lg btn-primary btn-block"
              onClick={handleSubmit}>Reset Password</button>
          </div>
        </div>

        <div className="form-note-s2 text-center pt-4">
          <Link to="/login"><strong>Return to login</strong></Link>
        </div>         
        </>
      )}

      {tab == 1 && (
        <>
        <div className="nk-block-head">
            <h4 className="nk-block-title">Password Reset Successfully.</h4>
        </div>
        <div className="nk-block-content">
            <p className="lead">Your password has been reset. Please login using the new password.</p>
            <div className="gap gap-md"></div>
            <Link className="btn btn-lg btn-block btn-primary" to="/login">Return to login</Link>
        </div>
        </>
      )}

    </Layout>
  )
}

export default Page;
