import {post} from './api';

let user = null;
export const getUser = async() => {
  if(user != null){
    return user;
  }

  const data = await post('/user/get');
  if(data?.status == 200){
    user = data.data;
  }
  return user;
}

let accounts = [];
export const getAccounts = async() => {
  // if(accounts.length > 0){
  //   return accounts;
  // }
  const data = await post('/wallet/balance', {});
  if(data.status == 200){
    accounts = data.data;
  }
  return accounts;
}

export const viewUser = () => {
  return user;
}

export const viewAccounts = () => {
  return accounts;
}
