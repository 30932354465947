import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Errors } from '../common/msg';
import {showAmount} from '../../libs/util';
import CurrencySelect from '../../components/form/currencySelect';

import {SelectField, useValidation, NumberField} from '../../components/form';
import useInterval from "../../libs/useInterval";


const Component = ({whitelists, wallets, setWithdrawalAmount, resetFee, fee, submitting, onSubmit}) => {

  const REFRESH_INTERVAL = 2;
  const CONFIRM_INTERVAL = 30; //sec

  const DECIMAL_USDC = '6'; //sec
  const DECIMAL_BTC = '8'; //sec

  const [amount, setAmount] = useState('');
  const [addressOptions, setAddressOptions] = useState([]);
  const [address, setAddress] = useState();
  const [whitelist, setWhitelist] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();
  const [selectedCurrency, setSelectedCurrency] = useState('USDT');

  const [countdown, setCountdown] = useState(REFRESH_INTERVAL);


  useEffect(() => {
    if(whitelists){
      let list = [];
      for(const white of whitelists){
        list.push({value: white.whitelist_id, label: white.note + ' - ' + white.address + ' - ' + white.currency + '(' + white.network + ')'});
      }
      setAddressOptions(list);
    }
  }, whitelists)

  const getWhitelist = (id) => {
    for(const white of whitelists){
      if(white.whitelist_id == id){
        return white;
      }
    }
    return null;
  }

  const fetchFee = () => {
    if (wallets && whitelist && amount || amount > 0.0) {
      setWithdrawalAmount(whitelist, wallet, amount);
    }
  }

  useEffect(() => {
    setWhitelist(getWhitelist(address));
    setAmount('');
    resetFee();
  }, [address])


  useEffect(() => {
    resetFee();
    setWithdrawalAmount(whitelist, wallet, amount);
  }, [amount])


  useInterval(() => {
     if (!wallets || !whitelist || !amount || amount <= 0.0) {
       resetFee();
       return;
     } else {
       //at intervals check fee
       if (countdown <= 0) {
         fetchFee();
         setCountdown(CONFIRM_INTERVAL);
       } else
        setCountdown(countdown - 1);
     }
  }, 1000);
  
  useEffect(() => {
    if(whitelist){
        setSelectedCurrency( whitelist?.currency.toUpperCase())
    }
  }, [whitelist])

  useEffect(() => {
    if(wallets && whitelist){
      if(whitelist.currency.toUpperCase === "BTC"){
        const BTCWallet = wallets.find(x => x.currency.toUpperCase() === "BTC");
        setWallet(BTCWallet);
      }else{
        const selectedWallet = wallets.find(x => x.currency.toUpperCase() === selectedCurrency.toUpperCase())
        setWallet(selectedWallet);
      }
      
    }
  }, [selectedCurrency, wallets, whitelist])

  const handleSubmit = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    if(!wallet){
      setErrors(['No wallet found.']);
      return;
    }
    if(parseFloat(amount) > wallet.main_balance){
      setErrors(['Exceed wallet balances.']);
      return;
    }

    if(parseFloat(amount) <= whitelist.fee){
      setErrors(['Withdrawal amount needs to be more than fee.']);
      return;
    }

    setErrors([])
    if(onSubmit){
      onSubmit(getWhitelist(address), wallet, amount);
    }
  }
  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value)  
};
  return (
    <>
    {errors.length > 0 && (
      <Errors errors={errors}/>
    )}
    <div className="form-validate is-alter mt-4 form-authentic">
      <div className="form-group">
        <label className="form-label" htmlFor="current-password">Whitelist Address  <span className="text-danger">*</span></label>
        <div className="form-control-wrap">
          <SelectField className="form-control" placeholder="Please select"
            required={true}
            showError={showError} onValidate={(valid) => setFieldValid('address', valid)}
            options={addressOptions}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Link to="/portal/wallet/whitelist/add" className="btn btn-primary mt-2">
                <span>Add Withdrawal Address</span></Link>              
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="amount">Amount <span className="text-danger">*</span></label>
        <div className="form-control-wrap">

        <div className="form-info">
          {whitelist?.currency.toUpperCase() === "BTC" ? (
            "BTC"
          ) : (
            <CurrencySelect
              value={selectedCurrency}
              onChange={handleCurrencyChange}
            />
          )}
        </div>

          <NumberField
              className="form-control form-control-lg form-control-number bg-white"
              placeholder="Enter Amount"
              value={amount} required={true} showError={showError}
              onValidate={(valid) => setFieldValid('amount', valid)}
              onChange={(e) => setAmount(e.target.value)}
              decimalPlaces={( whitelist?.currency.toUpperCase() == 'USDC' || whitelist?.currency.toUpperCase() == 'USDT')  ? DECIMAL_USDC : DECIMAL_BTC}
          />

        </div>
      </div>


      {wallet && (
        <div className="form-group">
          <div><label className="form-label" htmlFor="current-password">Available Balance: {showAmount(wallet.main_balance)}</label></div>
          <div><label className="form-label" htmlFor="current-password">Fee: {showAmount(fee)} 
          {whitelist?.currency.toUpperCase() === "BTC" ? 
          "BTC" : selectedCurrency.toUpperCase()}  (Deducted)</label>
            &nbsp;<button className="btn btn-xs btn-primary"
                          onClick={ (e) =>
                              fetchFee() }
            >Calculate</button></div>
        </div>
      )}
    
      {whitelist && (
      <div className="form-note-group">
        <span className="form-note-alt iv-invest-fixed">
          <em>Warning: Please send your {whitelist.currency} coins only on the {whitelist.network} network with the correct address. Sending it on a wrong network will result in the permanent loss of funds.</em>
        </span>
      </div>
      )}

      <div className="nk-pps-field form-action mt-4">
        <div className="nk-pps-action">
          <button className="btn btn-lg btn-block btn-primary" onClick={handleSubmit} disabled={!fee || fee <= 0.0}>
            <span>Submit Withdrawal Request</span>
            {submitting && (
              <span className="spinner-border spinner-border-sm" role="status"></span>
            )}
          </button>
        </div>
      </div>
    </div>    
    </>    
  )
}

export default Component;
