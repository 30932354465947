import {Link} from 'react-router-dom';
import {WarnAlert} from '../user/alert';

const Component = () => {
  return (
    <WarnAlert message="Your account have not been verified. You are unable to make a deposit or any investment purchases until your account is verifed.">
      <Link to="/profile/verification" className="btn btn-sm btn-primary">Start Verification</Link>
    </WarnAlert>
  )
}

export default Component;
