import {useState, useEffect, useContext} from 'react';
import Layout from '../../layouts/user';
import {Successes} from '../../components/common/msg';
import {SelectField, TextField} from "../../components/form";
import {showPrice, toUsdc} from "../../libs/util";
import {post} from "../../libs/api";
import {useNavigate, useParams} from "react-router-dom";
import UserContext from "../../libs/context";

function Page() {
    const {orderId} = useParams();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [successes, setSuccesses] = useState([]);
    const [order, setOrder] = useState();
    const {notification} = useContext(UserContext);
    const navigate = useNavigate();
    const [rateDesc, setRateDesc] = useState('');

    const loadOrder = async () => {
        setLoading(true);
        const data = await post('/swap/order/get', {orderId});
        setLoading(false);
        if (data?.status != 200) {
            setErrors([data?.error]);
            return;
        }
        setOrder(data.data);
    }

    useEffect(() => {
        setSuccesses(['The swap request has been fulfilled successfully.']);
        loadOrder();
    }, [])

    useEffect(() => {
        var rate = 0.0;
        if (order?.base_ccy == order?.swap_ccy) {
            rate = order?.swap_to_amount / order?.swap_amount;
        } else {
            rate = order?.swap_amount/ order?.swap_to_amount;
        }
        setRateDesc('1.0 ' + order?.base_ccy + ' = ' + showPrice(rate, ((order?.counter_ccy == 'USD') ? 'USDC' : order?.counter_ccy )) );
    }, [order])

    const handleBack = (e) => {
        e.preventDefault();
        navigate('/portal/wallet');
    }

    return (
        <Layout>
            <div className="page-invest wide-xs m-auto" id="iv-step-container">
                <div className="nk-pps-apps">
                    <div className="nk-pps-title text-center">
                        <h3 className="title">Swap Coin</h3>
                    </div>

                    {successes.length > 0 && (
                        <Successes msgs={successes}/>
                    )}

                    {order && (
                        <div className="form-validate is-alter">
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="current-password">Swap from</label>
                                        <div className="form-control-wrap">
                                            {order?.swap_ccy}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="current-password">Swap to</label>
                                        <div className="form-control-wrap">
                                            {order?.swap_to_ccy}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="nk-ivp-card card card-bordered card-full">
                                        <div className="card-inner-group">
                                            <div className="pl-2 pr-2">
                                                <div className="card-text">

                                                    <div className="row pt-2 mb-2 ">
                                                        <div className="col-4 text-left pl-4">
                                                            <span>Amount</span>
                                                        </div>
                                                        <div className="col-8 text-right pr-4">
                                                            <span> {showPrice(order?.swap_amount, order?.swap_ccy)} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2 mb-2 border-top">
                                                        <div className="col-4 text-left pl-4">
                                                            <span>Swap Rate</span>
                                                        </div>
                                                        <div className="col-8 text-right pr-4">
                                                            <span> {rateDesc}  </span>
                                                        </div>
                                                    </div>

                                                    {/*{ order.improve_rate && (*/}
                                                    {/*    <div className="row pt-2 mb-2 border-top">*/}
                                                    {/*    <div className="col-4 text-left pl-4">*/}
                                                    {/*        <span>Rate Reduction</span>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="col-8 text-right pr-4">*/}
                                                    {/*        <span> {"You get " + order.improve_rate + " less"}  </span>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*)}*/}


                                                    <div className="row pt-2 mb-2 border-top">
                                                        <div className="col-4 text-left pl-4">
                                                            <span>Final Amount</span>
                                                        </div>
                                                        <div className="col-8 text-right pr-4">
                                                            <span> {showPrice(order.swap_to_amount, order.swap_to_ccy)} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="nk-pps-field form-action">
                                <div className="nk-pps-action">
                                    <a href="#" className="btn btn-lg btn-block btn-primary"
                                       onClick={handleBack}>
                                        <span>Back to Wallet Balances</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Page;
