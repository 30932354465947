
const Component = ({id, title, children}) => {

  return (
    <>
    <div className="modal fade" tabIndex="-1" role="dialog" id={id}>
      <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <a href="#" className="close" data-dismiss="modal"><em className="icon ni ni-cross-sm"></em></a>
            <div className="modal-body modal-body-md">
              {title && (
                <h5 className="title">{title}</h5>
              )}
              {children}
            </div>                
          </div>
        </div>
    </div>
    </>
  )
}

export default Component;

