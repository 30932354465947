import {useState, useEffect, useRef} from 'react';
import { isEmpty } from './func';

const Component = ({id, className, placeholder, value, onChange, onValidate, showError, required, minLength, maxLength, disabled}) => {
  const [error, setError] = useState(null);

  const validate = () => {
    if(isEmpty(value)){
      if(required){
        return 'Required.';
      }
      return null;
    }
    if(minLength && minLength > 0){
      if(value.length < minLength){
        return 'At least ' + minLength + ' chars.';
      }
    }
    if(maxLength && maxLength > 0){
      if(value.length > maxLength){
        return 'At most ' + maxLength + ' chars.';
      }
    }
    return null;
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [value])

  return (
    <>
      <input type="text" id={id} className={className}
        placeholder={placeholder} disabled={disabled}
        value={value}
        onChange={onChange}/>
      {error && showError && (
        <span className="invalid">{error}</span>
      )}
    </>
  )
}

export default Component;
