import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Layout from '../../layouts/auth';
import { Errors } from '../../components/common/msg';
import { post } from '../../libs/api';

function Page() {
  const {code} = useParams();
  const [email, setEmail] = useState('');
  const [verifed, setVerified] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    handleSubmit();
  }, [])

  const handleSubmit = async () => {
    try{
      // send token to backend
      const data = await post('/auth/verify', {token: code});
      if(parseInt(data?.status) != 200){
        setErrors([data.error]);
        return;
      }
      setVerified(true);
    }
    catch(err){
      setErrors([err.message]);
    }
  }
  
  return (
    <Layout>
      {verifed && (
        <>
        <div className="nk-block-head text-center">
          <h4 className="nk-block-title">Congrats! You are Verified!</h4>
        </div>
        <div className="nk-block-content text-center">
          <p><strong>Thank you for your confirmation.</strong></p>
          <p>You have successfully verified your email address. Now you can login into your account and continue to use our platform.</p>
          <div className="gap gap-md"></div>
          <Link className="btn btn-lg btn-block btn-primary" to="/login">Login into Account</Link>
        </div>
        </>
      )}
      {errors.length > 0 && (
        <>
        <div className="nk-block-head text-center">
          <h4 className="nk-block-title">Verification Failed!</h4>
        </div>
        <div className="nk-block-content text-center">
          <Errors errors={errors}/>
        </div>
        </>
      )}
    </Layout>
  )
}

export default Page;
