import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import {Errors} from "../../components/common/msg";

import UserContext from "../../libs/context";
import useInterval from "../../libs/useInterval";
import { post } from '../../libs/api';

function Page(props) {
  const {maintMode, setMaintMode, logout} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!maintMode){
      logout();
      setMaintMode(true);  
    }
    checkLiveness();
  }, []);

  const checkLiveness = async() => {
    const data = await post('/auth/live');
    if(data?.data?.live){
      // it is live
      setMaintMode(false);
      navigate('/login');
    }
  }

  useInterval(() => {
    checkLiveness();
  }, 3000);

  return (
      <Layout>
        <div className="nk-block-head">
          <h4 className="nk-block-title text-center">Down for Maintenance</h4>
        </div>
        <div className="nk-block-content">
          <p className="lead">We are performing scheduled maintenance.</p>
          <p className="lead"> We should be back online shortly.</p>
        </div>
      </Layout>
  )
}

export default Page;
