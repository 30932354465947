import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import WalletNav from '../../components/wallet/nav';
import NeedVerification from '../../components/common/needVerification';
import {getUser} from '../../libs/user';
import { post } from '../../libs/api';
import {showAmount} from '../../libs/util';

function Page() {

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [transactions, setTransactions] = useState([]);
  
  const loadTransactions = async() => {
    setLoading(true);
    // const user = await getUser();
    // if(user){
    //   setVerified(user.profileVerified);
    // }

    const data = await post('/wallet/transaction');
    if(data?.status == 200){
      setTransactions(data.data);
      // setErrors([data.error]);
      // return;
    }

    // const list = [
    //   {
    //     id: 1,
    //     address: '0x11111',
    //     coin: 'ETH',
    //     notes: 'hehel',
    //     date: '2022-09-01',
    //     status: 'Active'
    //   }
    // ]
    // setTransactions(list);
    setLoading(false);
  }

  useEffect(() => {
    loadTransactions();
  }, [])

  return (
    <Layout>
      <UserHead sub="Wallet Management" title="Transaction History" description="List of transactions in your account.">
        <ul className="nk-block-tools gx-3">          
          <li><Link to="/portal/deposit" className="btn btn-primary"><span>Deposit</span> <em className="icon ni ni-arrow-long-right"></em></Link></li>
        </ul>
      </UserHead>    

      <WalletNav active="transaction"/>         

      <div className="nk-block nk-block-xs">
        {!loading && !verified && (
          <NeedVerification/>
        )}

        {!loading && verified && (
        <div className="card card-bordered">
          <table className="table table-ulogs">
            <thead className="thead-light">
            <tr>
              <th className="tb-col-os"><span className="overline-title">Txn Ref</span></th>
              <th className="tb-col-time"><span className="overline-title">Type</span></th>
              <th className="tb-col-time"><span className="overline-title">Status</span></th>
              <th className="tb-col-time"><span className="overline-title">Coin</span></th>
              <th className="tb-col-time"><span className="overline-title">Amount</span></th>
              <th className="tb-col-time"><span className="overline-title">Fee</span></th>
              <th className="tb-col-time"><span className="overline-title">Date</span></th>
              <th className="tb-col-time"><span className="overline-title">Remark</span></th>
            </tr>
            </thead>
            <tbody>
            {transactions && transactions.map((t, i) => (
              <tr key={i}>
                {t.deposit ? (
                  <>
                  <td className="tb-col-os"><span className="sub-text">{t.deposit.reference_id}</span></td>
                  <td className="tb-col-time"><span className="sub-text">Deposit</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.deposit.status}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.deposit.currency}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{showAmount(t.deposit.amount)}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{showAmount(0.0)}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.deposit.transaction_date}</span></td>
                  <td className="tb-col-time"><span className="sub-text">&nbsp;</span></td>
                  </>

                ) : (
                  <>
                  <td className="tb-col-os"><span className="sub-text">{t.withdrawal?.reference_id}</span></td>
                  <td className="tb-col-time"><span className="sub-text">Withdrawal</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.withdrawal?.status}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.withdrawal?.wallet?.currency}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{showAmount(t.withdrawal?.amount)}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{showAmount(0.0)}</span></td>
                  <td className="tb-col-time"><span className="sub-text">{t.withdrawal?.transaction_date}</span></td>
                  <td className="tb-col-time"><span className="sub-text">&nbsp;</span></td>
                  </>
                )}
              </tr>
            ))}
            </tbody>
          </table>
        </div>    
        )}

      </div>
    </Layout>
  )
}

export default Page;
