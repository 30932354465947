import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserHead from '../../../components/user/head';
import ProfileNav from '../../../components/user/profileNav';
import {InfoAlert, WarnAlert} from '../../../components/user/alert';
import Dialog from '../../../components/common/dialog';
import UpdateProfile from '../../../components/user/updateProfile';
import PersonalProfileForm from '../../../components/user/personalProfileForm';
import { getUser } from '../../../libs/user';
import { post } from '../../../libs/api';
import { showOption } from '../../../libs/util';
import countries from '../../../config/countries';

function Page(props) {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState([]);

  const loadAccounts = async() => {
    const user = await getUser();
    if(user){
      setUser(user);
    }

    const data = await post('/profile/get');
    if(data?.status == 200){
      setProfile(data.data.fields);
    }
  }

  const updatePersonal = async(updated) => {
    setErrors([]);
    const data = await post('/profile/update', updated);
    if(data?.status == 200){
      setProfile({...updated});
    }
    else{
      setErrors([data.error]);
    }
  }

  useEffect(() => {
    loadAccounts();
  }, [])

  return (
    <Layout menu="profile">  
      <UserHead title="My Profile" description=""/>
      <ProfileNav active="settings"/>

      <div className="nk-block">                        

          
        <div className="card card-bordered">
            <div className="nk-data data-list">
              {user && (
                <>
                <div className="data-item">
                  <div className="data-col">
                        <span className="data-label">Email</span>
                        <span className="data-value">{user.email}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span>
                    </div>
                </div>
                  <div className="data-item">
                    <div className="data-col">
                        <span className="data-label">User Name</span>
                        <span className="data-value">{profile.full_name}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span>
                    </div>
                </div>

                <div className="data-item">
                    <div className="data-col">
                        <span className="data-label">Date of Birth</span>
                        <span className="data-value">
                            {profile.dob}
                        </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span>
                    </div>
                </div>
                <div className="data-item">
                    <div className="data-col">
                        <span className="data-label">Country of Residence</span>
                        <span className="data-value">
                            {showOption(profile.country, countries)}
                        </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span>
                    </div>
                </div>
                <div className="data-item">
                    <div className="data-col">
                        <span className="data-label">Nationality</span>
                        <span className="data-value">
                          {showOption(profile.nationality, countries)}
                        </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span>
                    </div>
                </div>

                </>
              )}
            </div>
        </div>
      </div>

    </Layout>
  )
}

export default Page;
