import {Link} from 'react-router-dom';
import CurrencyIcon from '..//common/icon';
import { showDateTime, showPrice } from '../../libs/util';

const Component = ({transaction, referenceId}) => {
  return (
      <>
        <div className="dark-text">TXN ID #{referenceId}</div>
        <div className="dark-text">Redemption {transaction.currency} via Investment Maturity</div>
        <div className="nk-odr-item p-0 pt-1 pb-2">
          <div className="nk-odr-col">
            <div className="nk-odr-info">
              <div className="nk-odr-badge">
                <CurrencyIcon currency={'re'}/>
              </div>
              <div className="nk-odr-data">
                <div className="nk-odr-label">{showPrice(transaction.amount, transaction.currency)}</div>
                <div className="nk-odr-meta">{showDateTime(transaction.transaction_date)}</div>
              </div>
            </div>
          </div>
          <div className="nk-odr-col nk-odr-col-amount text-right">{transaction.trade_status}</div>
        </div>

        <div className="mt-1 dark-text">REDEMPTION DETAILS</div>
        <div className="row mt-1">
          <div className="col-6">
            <span className="lead-text">Redeemed Amount</span>
            <span className="sub-text">{showPrice(transaction.amount, transaction.currency)}</span>
          </div>
          <div className="col-6">
            <span className="lead-text">Credited in Wallet</span>
            <span className="sub-text">{showPrice(transaction.amount, transaction.currency)}</span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-6">
            <span className="lead-text">Detail</span>
            <span className="sub-text">Redeemed {transaction.currency} via Maturity of Investment</span>
          </div>
          <div className="col-6">
            <span className="lead-text">&nbsp;</span>&nbsp;
          </div>
        </div>

        <div className="mt-2 dark-text border-top pt-1">TIMELINE</div>
        <div className="row mt-1">
          <div className="col-6"><span className="lead-text">Redeemed On</span><span className="sub-text">{showDateTime(transaction.transaction_date)}</span></div>

        </div>
      </>
  )
}

export default Component;
