export const secretQuestion1Options = [
  {value: 'Where was your most memorable holiday?', label: 'Where was your most memorable holiday?'},
  {value: 'What is the best gift you have ever received?', label: 'What is the best gift you have ever received?'},
];

export const secretQuestion2Options = [
  {value: 'What is your best friend’s nickname?', label: 'What is your best friend’s nickname?'},
  {value: 'What is the name of your favourite teacher?', label: 'What is the name of your favourite teacher?'},
  {value: 'What is the make and model of your 1st car?', label: 'What is the make and model of your 1st car?'},
];

export const secretQuestion3Options = [
  {value: 'What is your favourite movie?', label: 'What is your favourite movie?'},
  {value: 'What is your favourite book?', label: 'What is your favourite book?'},
  {value: 'What is your favourite food?', label: 'What is your favourite food?'},
];
