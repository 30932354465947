import {Link} from 'react-router-dom';
import CurrencyIcon from '..//common/icon';
import { showDateTime, showPrice } from '../../libs/util';

const Component = ({transaction, referenceId}) => {
  return (
    <>
    <div className="dark-text">TXN ID #{referenceId}</div>
    <div className="dark-text">Withdrawal {transaction.wallet?.currency} to External Wallet</div>
    <div className="nk-odr-item p-0 pt-1 pb-2">
      <div className="nk-odr-col">
        <div className="nk-odr-info">
          <div className="nk-odr-badge">
            {/*<CurrencyIcon currency={transaction.wallet?.currency}/> */}
            <CurrencyIcon currency={'wd'}/>

          </div>
          <div className="nk-odr-data">
            <div className="nk-odr-label text-danger">-{showPrice(transaction.amount, transaction.wallet?.currency)}</div>
            <div className="nk-odr-meta">{showDateTime(transaction.transaction_date)}</div>
          </div>
        </div>
      </div>
      <div className="nk-odr-col nk-odr-col-amount text-right">{transaction.trade_status}</div>
    </div>

    <div className="mt-1 dark-text">WITHDRAWAL DETAILS</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Withdrawal Amount</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.wallet?.currency)}</span>
      </div>
      <div className="col-6">
        <span className="lead-text">Dedited from Wallet</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.wallet?.currency)}</span>
      </div>
    </div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Detail</span>
        <span className="sub-text">Withdrawal {transaction.wallet?.currency} to External Wallet</span>
      </div>
      <div className="col-6">
        <span className="lead-text">&nbsp;</span>&nbsp;
      </div>
    </div>

    <div className="mt-2 dark-text border-top pt-1">ADDITIONAL</div>
    <div className="row mt-1">
      <div className="col-5">
        <span className="lead-text">Withdrawal Method</span>
        <span className="sub-text">External Wallet</span>
      </div>
      <div className="col-7">
        <span className="lead-text">To Wallet</span>
        <span className="sub-text">{transaction.whitelist?.address}</span>
      </div>
    </div>
    <div className="row mt-1">
      <div className="col-5">
        <span className="lead-text">Reference</span>
        <span className="sub-text">N/A</span>
      </div>
      <div className="col-7">
        <span className="lead-text">Updated Balance</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.wallet?.currency)}</span>
      </div>
    </div>

    <div className="mt-2 dark-text border-top pt-1">TIMELINE</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Requested On</span>
        <span className="sub-text">{showDateTime(transaction.transaction_date)}</span>
      </div>
      <div className="col-6">
        <span className="lead-text">Complete On</span>
        <span className="sub-text">{showDateTime(transaction.transaction_date)}</span>
      </div>
    </div>    
    </>
  )
}

export default Component;
