import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import Layout from '../../layouts/user';
import {SelectField, useValidation, NumberField} from '../../components/form';

import {Errors, Successes} from '../../components/common/msg';
import {post} from '../../libs/api';
import {showPrice, toUsdc} from '../../libs/util';

import useInterval from '../../libs/useInterval';

function Page() {
    const REFRESH_INTERVAL = 2; //parseInt(process.env.REACT_APP_COUNTDOWN);
    const CONFIRM_INTERVAL = 20;

    const {swapCurrency} = useParams();
    const [isSwapMtn, setIsSwapMtn] = useState(false);
    const [balance, setBalance] = useState(0);
    const [title, setTitle] = useState('');
    const [wallet, setWallet] = useState(null);
    const [amount, setAmount] = useState('');
    const [rateDesc, setRateDesc] = useState('');
    const [rate, setRate] = useState(0.0);
    const [rateCcy, setRateCcy] = useState('');
    const [toAmount, setToAmount] = useState(0.0);
    const [fromCurrency, setFromCurrency] = useState('');
    const [toCurrency, setToCurrency] = useState('');
    const [orderbook, setOrderbook] = useState(null);

    const [errors, setErrors] = useState([]);
    const [errors2, setErrors2] = useState([]);
    const [successes, setSuccesses] = useState([]);
    const [showError, setShowError] = useState(false);
    const [setFieldValid, isValid] = useValidation();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(0);
    const [countdown, setCountdown] = useState(REFRESH_INTERVAL);
    const [countdownInterval, setCountdownInterval] = useState(REFRESH_INTERVAL);
    const [hasPrice, setHasPrice] = useState(false);
    const navigate = useNavigate();

    const coinOptions = [
        {value: 'BTC', label: 'BTC'},
        {value: 'ETH', label: 'ETH'},
        {value: 'USDC', label: 'USDC'},
        {value: 'USDT', label: 'USDT'}
    ];

    const checkSwapMtnStatus = async() => {
        const data = await post('/auth/mtn/swap');
        if(data?.data?.swapmtn){
            // it is live
            setIsSwapMtn(false);
            setErrors([]);
        } else {
            setIsSwapMtn(true);
            setErrors(['Swap application is unavailable. Please try again later.']);

        }
    }

    useInterval(() => {
        if (!fromCurrency || !toCurrency) {
            return;
        }

        if (countdown == 0) {
            reloadOrderbook(fromCurrency, toCurrency);
            checkSwapMtnStatus();
            setCountdown(countdownInterval);
            return;
        }
        setCountdown(countdown - 1);
    }, 1000);


    useEffect(() => {
        setFromCurrency(swapCurrency);
    }, [swapCurrency]);



    useEffect(() => {
        setAmount('');
        onCurrencyChange();
    }, [fromCurrency])


    useEffect(() => {
      if(!fromCurrency){
        setTitle('');
        return;
      }
        setErrors2([]);
        if (!toCurrency || toCurrency == '' || (fromCurrency == toCurrency) ) {
            if (fromCurrency == 'BTC')
                setToCurrency("ETH");
            else
                setToCurrency("BTC");
          return;
        }
        let strTitle = "";
        let dst = toCurrency.toUpperCase();
        let src = fromCurrency.toUpperCase();
        strTitle = src + ' to ' + dst
        setTitle('Swap ' + strTitle);
        loadOrderbooks(fromCurrency, toCurrency);
    }, [fromCurrency, toCurrency])

    useEffect(() => {
        setErrors2([]);
        calculateAmount();
        // setToAmount(amount * rate);
        // setRateDesc('1.0 ' + fromCurrency + ' = ' + rate + ' ' + toCurrency);
    }, [amount, rate])

    useEffect(() => {
        setBalance(wallet ? wallet.main_balance : 0);
    }, [wallet]);

    useEffect(() => {
        //setErrors([]);
        setRateCcy(fromCurrency);
        setToAmount(0.0);
        setRate(0.0);
        if (!orderbook) {
            setErrors(['Swap rate not available']);
            return;
        }
        if (orderbook.rate == 0.0) {
            setErrors(['Swap rate not available']);
            //return;
        }

        setHasPrice(true);
        setErrors([]);
        // if (fromCurrency == orderbook.baseCcy) {
        //   setRate(orderbook.rate);
        // } else {
        //     setRate(1 / orderbook.rate);
        // }
        calculateAmount();
        let value = parseFloat(amount);
        if (isNaN(value)) {
            return;
        }

    }, [orderbook])

    const calculateAmount = () => {
        if (orderbook) {

            if (fromCurrency == orderbook.baseCcy) {
                setRate(orderbook.rate);
                setToAmount(amount * (orderbook.rate));
            } else {
                setRate(1 / orderbook.rate);
                setToAmount(amount * (1 / orderbook.rate));
            }
            setRateDesc('1.0 ' + orderbook.baseCcy + ' = ' + showPrice(orderbook.rate , orderbook.counterCcy ));
        }
    }

    const onCurrencyChange = async () => {
        // load wallet
        setLoading(true);
        const w = await fetchWallet();
        if (!w) {
            setErrors(['Could not get wallet balances']);
            setLoading(false);
            return;
        }
        setWallet(w);
        setLoading(false);
    }

    const fetchOrderbooks = async () => {
        if (!fromCurrency || fromCurrency == '' || !toCurrency || toCurrency == '') {
            setTitle('');
            return;
        }

        if (fromCurrency == toCurrency) {
            if (fromCurrency == 'BTC')
                setToCurrency("ETH");
            else
                setToCurrency("BTC");
        }
        return await post('/swap/orderbook', {fromCurrency, toCurrency, amount});
    }

    const loadOrderbooks = async (from, to) => {
        setHasPrice(false);
        setErrors([]);
        const data = await fetchOrderbooks();
        if(from != fromCurrency || to != toCurrency){
          // skip
          return;
        }
        if (data?.status != 200) {
            console.log('error', data);
            setErrors([data.error]);
            setOrderbook(null);
            return;
        }
        if (data.data?.length == 0) {
            setErrors(['Swap rate not available']);
            setOrderbook(null);
            return;
        }
        
        setOrderbook(data.data);
    }

    const reloadOrderbook = async (from, to) => {
        const data = await fetchOrderbooks();
        if(from != fromCurrency || to != toCurrency){
          // skip
          return;
        }
        if (data?.status == 200)
            setOrderbook(data.data);
    }

    const fetchWallet = async () => {
        if (fromCurrency && fromCurrency != "") {
            const data = await post('/wallet/balance', {});
            if (data?.status == 200) {
                for (const w of data.data) {
                    if (w.currency == fromCurrency) {
                        return w;
                    }
                }
            }
        }
        return null;
    }


    useEffect(() => {
        if (step == 0) {
            setCountdownInterval(REFRESH_INTERVAL);
            setCountdown(REFRESH_INTERVAL);
        } else {
            setCountdownInterval(CONFIRM_INTERVAL);
            setCountdown(CONFIRM_INTERVAL);
        }
    }, [step]);



    const handleStep1Submit = async (e) => {

        e.preventDefault();
        setErrors2([]);
        if (!isValid('amount')) {
            setShowError(true);
            return;
        }

        const value = parseFloat(amount);
        if (value == NaN) {
            setErrors(['Please provide numeric value.']);
            return;
        }
        if (String(amount).lastIndexOf('.') >= 0 && (String(amount).length - String(amount).lastIndexOf('.')) > 9) {
            setErrors2(['Please limit to 8 decimal places.']);
            return;
        }
        if (value > wallet?.main_balance) {
            setErrors2(['Exceeded wallet balances.']);
            return;
        }
        if (value < orderbook?.min_amount) {
            setErrors2(['Did not meet minimum amount of '  + orderbook?.min_amount + ' ' + fromCurrency]);
            return;
        }

        if (value > orderbook?.max_amount) {
            setErrors2(['Exceeded maximum available amount of ' + orderbook?.max_amount + ' ' + fromCurrency]);
            return;
        }

        if (submitting) {
            return;
        }

    //     setShowConfirm(true);
    //   }
    //
    // const handleConfirm = async (e) => {
    //     if (e) {
    //       e.preventDefault();
    //     }
    //     setShowConfirm(false);

        try {
            setSubmitting(true);

            setLoading(true);
            // submit an order
            let order = {
                wallet_id: wallet.wallet_id,
                swap_amount: parseFloat(amount).toFixed(8),
                swap_ccy: wallet.currency,
                swap_to_amount: parseFloat(toAmount).toFixed(8),
                swap_to_ccy: toCurrency,
                swap_rate: parseFloat(rate).toFixed(8),
                base_ccy: orderbook?.baseCcy,
                counter_ccy: orderbook?.counterCcy
            };
            const result = await post('/swap/order/add', order);
            if (result?.status != 200) {
                console.log('list error', result);
                setErrors2([result?.error]);
                setLoading(false);
                return;
            }
            navigate('/portal/swaped/' + result.data.transaction_id);
        } catch (e) {

            console.log('catch error', e);
        } finally {
            setSubmitting(false);
            setLoading(false);
        }
    }

  //   const handleConfirmClose = (e) => {
  //     if (e) {
  //         e.preventDefault();
  //     }
  //     setShowConfirm(false);
  // }

    const handleBack = (e) => {
        e.preventDefault();
        navigate('/portal/wallet');
    }

    return (
        <Layout>
            <div className="page-invest wide-xs m-auto" id="iv-step-container">
                <div className="nk-pps-apps">
                    <div className="nk-pps-title text-center">
                        <h3 className="title">{title}</h3>
                    </div>

                    {errors?.length > 0 && (
                        <Errors errors={errors}/>
                    )}
                    {errors2?.length > 0 && (
                        <Errors errors={errors2}/>
                    )}
                    {successes?.length > 0 && (
                        <Successes msgs={successes}/>
                    )}
                    <div className="form-validate is-alter">

                    {isSwapMtn==false && orderbook && (
                        <>
                            {step == 0 && (

                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="current-password">Swap from <span className="text-danger">*</span></label>
                                                <div className="form-control-wrap">
                                                    <SelectField className="form-control" placeholder="Please select"
                                                                 options={coinOptions}
                                                                 value={fromCurrency}
                                                                 onChange={(e) => setFromCurrency(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="current-password">Swap to <span className="text-danger">*</span></label>
                                                <div className="form-control-wrap">
                                                    <SelectField className="form-control" placeholder="Please select"
                                                                 options={coinOptions}
                                                                 value={toCurrency}
                                                                 onChange={(e) => setToCurrency(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {toCurrency && (
                                            <>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="amount">Amount <span className="text-danger">*</span></label>
                                                <div className="form-control-wrap">

                                                    <div
                                                        className="form-info">{toUsdc(fromCurrency?.toUpperCase())}</div>
                                                        <NumberField
                                                            className="form-control form-control-lg form-control-number bg-white"
                                                            placeholder={"Minimum amount - " + orderbook.min_amount}
                                                            value={amount} required={true} showError={showError}
                                                            onValidate={(valid) => setFieldValid('amount', valid)}
                                                            onChange={(e) => setAmount(e.target.value)}/>

                                                </div>
                                            </div>

                                            <div className="form-note-group">
                                                  <span className="form-note-alt">
                                                      <a href="#" onClick={ (e) => setAmount(wallet.main_balance)}>
                                                        Wallet Balances: {(wallet) ? showPrice(wallet.main_balance, wallet.currency) : ''}
                                                      </a>
                                                      &nbsp;<button className="btn btn-xs btn-primary"
                                                                    onClick={ (e) =>
                                                                        setAmount( (orderbook.max_amount < wallet.main_balance) ? orderbook.max_amount : wallet.main_balance) }
                                                  >MAX</button>
                                                  </span>
                                            </div>

                                            <div className="form-note-group">
                                                  <span className="form-note-alt">
                                                    Maximum Amount: {(orderbook) ?  showPrice(orderbook.max_amount, fromCurrency) : ''}
                                                  </span>
                                            </div>

                                            <div className="form-note-group">
                                                  <span className="form-note-alt">
                                                    Fee: {(wallet) ? showPrice(0.00, wallet.currency)  : ''}
                                                  </span>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                                <div className="nk-ivp-card card card-bordered card-full">
                                                    <div className="card-inner-group">
                                                        <div className="pl-2 pr-2">
                                                            <div className="card-text">

                                                                <div className="row pt-2 mb-2 ">
                                                                    <div className="col-4 text-left pl-4">
                                                                        <span>Swap Rate</span>
                                                                    </div>
                                                                    <div className="col-8 text-right pr-4">
                                                                      {hasPrice && (
                                                                        <span> {rateDesc} </span>
                                                                      )}                                                                        
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-2 mb-2 border-top">
                                                                    <div className="col-4 text-left pl-4">
                                                                        <span>Final Amount</span>

                                                                    </div>
                                                                    <div className="col-8 text-right pr-4">
                                                                    {hasPrice && (
                                                                        <span> {showPrice(toAmount, toCurrency)}</span>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                            </>
                                        )}


                                </div>
                            )}


                            {orderbook && (
                                <>
                                    {step == 0 && (
                                        <>
                                            {hasPrice && (
                                                <>
                                                {loading ? (
                                                    <div className="loader-container text-center">
                                                        <ClipLoader color={'#1f2b3a'} size={10} />
                                                        <p>Loading, please wait a moment ...</p>
                                                    </div>
                                                    ) : (
                                                    <div className="nk-pps-field form-action mt-1">
                                                        <div className="nk-pps-action">
                                                            <a href="#" className="btn btn-lg btn-block btn-primary"
                                                              onClick={handleStep1Submit}>
                                                                <span>Swap Now</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    )}
                                                </>
                                            )}

                                        </>
                                    )}

                                </>
                            )}
                        </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Page;
