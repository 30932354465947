import {Link} from 'react-router-dom';

const Layout = ({children}) => {
    return (
        <div className="nk-body npc-cryptlite ">
            <div className="nk-app-root">
                <div className="nk-wrap">
                    <div className="nk-block nk-block-middle nk-auth-body wide-lg">
                        <div className="brand-logo text-center mb-2 pb-4">
                            <Link className="logo-link" to="/">
                                <img className="logo-img logo-light logo-img-lg" src="/images/logo-white.svg"
                                     srcSet="/images/logo-white.svg" alt="Penguin Securities"/>
                                <img className="logo-img logo-dark logo-img-lg" src="/images/logo-red.svg"
                                     srcSet="/images/logo-red.svg" alt="Penguin Securities"/>
                            </Link>
                        </div>

                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                    <div className="row g-3">
                        <div className="col-lg-6 order-lg-last">
                            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                                <li className="nav-item">
                                    <a className="nav-link" target={"_blank"} href="https://penguinsecurities.sg/privacy-policy">Legal Notices</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="nk-block-content text-center text-lg-left">
                                <p className="text-soft">&copy; Penguin Securities All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Layout;