import {useState, useEffect, useRef} from 'react';
import { SelectField, TextField, CheckField, useValidation } from '../../components/form';
import { Tooltip } from 'react-tooltip'


const Component = ({onSubmit}) => {

  const coinOptions = [
    {value: 'BTC', label: 'BTC'},
    {value: 'ETH', label: 'ETH'},
    {value: 'USDC', label: 'USDC'},
    {value: 'USDT', label: 'USDT'}
  ];

  const networkOptions = [
    {value: 'BTC', label: 'BTC'},
    {value: 'ETH', label: 'ERC20'},
  ];

  const purposeOptions = [
    {value: 'WITHDRAW', label: 'Withdrawal'},
    {value: 'DEPOSIT', label: 'Deposit'},
  ];

  const fileRef = useRef(null);

  const [purpose, setPurpose] = useState('');
  const [coin, setCoin] = useState('');
  const [network, setNetwork] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [vasp, setVasp] = useState('');
  const [vaspDetail, setVaspDetail] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');

  const [isHardware, setIsHardware] = useState(false);
  const [showAddress, setShowAddress] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleSelectProvider = (e) =>{
    setVasp(e.target.value);
    const { label, value, ...details } = e.target;
    setVaspDetail(JSON.stringify(details))
  }

  useEffect(() => {
    const displayAdd = (purpose.toLowerCase() !== "deposit" || 
    (purpose.toLowerCase() === "deposit" && isHardware));
    setShowAddress(displayAdd)
  },[isHardware, purpose])


  const handleSubmit = async(e) => {
    if(!showAddress) {
      setFieldValid('address', true)
    }

    if(!isValid()){
      setShowError(true);
      return;
    }

    setSubmitting(true);
    if(onSubmit){
      onSubmit(purpose, coin, network, address, notes, isHardware, vasp, vaspDetail, beneficiaryName, fileRef.current);
    }
  }

  const isBtc = (coin) => {
    return coin == 'BTC';
  }

  useEffect(() => {
    if(!coin){
      return;
    }
    if(isBtc(coin) && network != 'BTC'){
      setNetwork('BTC');
    }
    else if(!isBtc(coin) && network != 'ETH'){
      setNetwork('ETH');
    }
  }, [coin])

  useEffect(() => {
    if(!network){
      return;
    }
    if(network == 'BTC' && !isBtc(coin)){
      setCoin('BTC');
    }
    else if(network != 'BTC' && isBtc(coin)){
      setCoin('ETH');
    }
  }, [network])

  return (
    <>
    <div className="form-validate is-alter mt-4">
      <div className="row gy-4">
        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label" htmlFor="current-password">Purpose <span
                className="text-danger">*</span></label>
            <div className="form-control-wrap">
              <SelectField className="form-control" placeholder="Please select"
                           required={true} showError={showError} onValidate={(valid) => setFieldValid('purpose', valid)}
                           options={purposeOptions}
                           value={purpose}
                           onChange={(e) => setPurpose(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label" htmlFor="current-password">Coin <span className="text-danger">*</span></label>
            <div className="form-control-wrap">
              <SelectField className="form-control" placeholder="Please select"
                           required={true} showError={showError} onValidate={(valid) => setFieldValid('coin', valid)}
                           options={coinOptions}
                           value={coin}
                           onChange={(e) => setCoin(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label" htmlFor="current-password">Network <span
                className="text-danger">*</span></label>
            <div className="form-control-wrap">
              <SelectField className="form-control" placeholder="Please select"
                           required={true} showError={showError} onValidate={(valid) => setFieldValid('network', valid)}
                           options={networkOptions}
                           value={network}
                           selectDisabled={coin.toUpperCase() === "BTC"}
                           onChange={(e) => setNetwork(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label" htmlFor="notes">Nickname of Address</label>
            <div className="form-control-wrap">
              <TextField className="form-control form-control-lg" placeholder="Enter nickname"
                         maxLength={190}
                         showError={showError} onValidate={(valid) => setFieldValid('notes', valid)}
                         value={notes}
                         onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          <div className="form-note-group">
                <span className="form-note-alt iv-invest-fixed">
                  <em>Note: Use a nickname for this address.</em>
                </span>
          </div>
        </div>
        <div className="col-md-12">
          <hr/>
          <div className="form-group">
            <label className="form-label" htmlFor="notes">Owner's Full Name <span
                className="text-danger">*</span></label>
            <div className="form-control-wrap">
              <TextField className="form-control form-control-lg" placeholder="Enter owner's full name."
                         required={true} maxLength={190}
                         showError={showError} onValidate={(valid) => setFieldValid('beneficiaryName', valid)}
                         value={beneficiaryName}
                         onChange={(e) => setBeneficiaryName(e.target.value)}
              />
            </div>
          </div>
          {/*<div className="form-note-group">*/}
          {/*  <span className="form-note-alt iv-invest-fixed">*/}
          {/*    <em>Note: Enter the receiver's full name.</em>*/}
          {/*  </span>*/}
          {/*</div>*/}

        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label" htmlFor="address">Address <span className="text-danger">*</span></label>
            <div className="form-control-wrap">
              <TextField id={"address"} className="form-control form-control-lg" placeholder="Enter address"
                         required={showAddress} maxLength={190}
                         showError={showError}
                         onValidate={(valid) => {
                           setFieldValid('address', valid)
                         }}
                         value={address}
                         onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="form-note-group">
                <span className="form-note-alt iv-invest-fixed">
                  <em>Warning: Please send your ETH coins only on the ERC20 network with the correct address. Sending it on a wrong network will result in the permanent loss of funds.</em>
                </span>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-control-wrap">
            {/* <div className="custom-control custom-checkbox"> */}
            <CheckField className="custom-control-input"
                        checked={isHardware}
                        onChange={(checked) => setIsHardware(checked)}
            >
              This is a hardware/software wallet
              &nbsp;<em data-tooltip-id="my-tooltip-ishardware" className="icon ni ni-info text-soft nk-tooltip"></em>

              <Tooltip id="my-tooltip-ishardware"
                       style={{backgroundColor: "#101924", color: "#FFF"}}
              >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p>There are two types of wallets: hosted and unhosted.</p>

                  <p>Hosted wallets are those that are offered to you by service providers as part of their
                    platform <br/>
                    or exchange, e.g., Coinhako, OKX, Gate.io. </p>

                  <p>Unhosted wallets are either software wallets, which are downloaded and installed on a desktop <br/>
                    or mobile device, or hardware wallets, which are physical devices designed to store your <br/>
                    private keys offline.</p>

                </div>
              </Tooltip>
            </CheckField>

            {/* </div> */}
          </div>
          <div className="form-note-group">
            <span className="form-note-alt iv-invest-fixed">
              <em> Examples of hardware/software wallets are Ledger, Exodus, Metamask or Trust Wallet.</em>
            </span>
          </div>
        </div>

        {(!isHardware) && (
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="address">Service Provider <span
                      className="text-danger">*</span></label>
                  <div className="form-control-wrap">

                    <SelectField className="form-control" placeholder="Search by entering keywords of provider"
                                 required={true} showError={showError}
                                 onValidate={(valid) => setFieldValid('coin', valid)}
                                 value={{value: vasp, label: vasp}}
                                 onChange={(e) => handleSelectProvider(e)}
                                 asyncUrl="/wallet/whitelist/vasp/list"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="address">Proof of Ownership</label>
                  <div className="form-control-wrap">
                    <input type="file" ref={fileRef}/>
                  </div>
                </div>

                <div className="form-note-group">
            <span className="form-note-alt iv-invest-fixed">
              <em>Note: If you are unable to locate your service provider, please contact us at <a
                  href={"mailto:support@penguinsecurities.sg"}>support@penguinsecurities.sg</a>.
                </em>
            </span>
                </div>
              </div>

            </>
        )}

      </div>

      <div className="nk-pps-field form-action">
        <div className="nk-pps-action">
          <button className="btn btn-lg btn-block btn-primary" onClick={handleSubmit}>
            <span>Save Address</span>
            {submitting && (
                <span className="spinner-border spinner-border-sm" role="status"></span>
            )}
          </button>
        </div>
      </div>

      {/* <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
          <li><button type="button" id="update-password" className="btn btn-primary">Save Address</button></li>
          <li><a href="#" data-dismiss="modal" className="link link-light">Cancel</a></li>
      </ul> */}
    </div>
    </>
  )
}

export default Component;
