import { Link } from "react-router-dom";

const Component = ({children, sub, title, description, showBack, backTo}) => {
  return (
    <div className="nk-block-head nk-block-head-sm">
      {sub && (
        <div className="nk-block-head-sub">
          {showBack && (
            <Link to={backTo}><em className="icon ni ni-arrow-left"></em></Link>
          )}
          <span> {sub}</span>
        </div>
      )}        
      <div className="nk-block-between-md g-4">
        <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">{title}</h2>
            {description && (
              <div className="nk-block-des">
                <p>{description}</p>
              </div>
            )}
        </div>
        <div className="nk-block-head-content d-none d-md-inline-flex">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Component;
