import {Link} from 'react-router-dom';

const Component = ({active}) => {
  const items = [
    {key: 'balance', 'text': 'Balances', 'link': '/portal/wallet'},
    {key: 'transaction', 'text': 'Transaction History', 'link': '/portal/wallet/transaction'},
    {key: 'whitelist', 'text': 'Whitelist', 'link': '/portal/wallet/whitelist'}
  ]

  return (
    <ul className="nk-nav nav nav-tabs">
      {items.map((item, i) => (
        <li className={active == item.key ? 'nav-item active' : 'nav-item'} key={i}>
          <Link className="nav-link" to={item.link}>{item.text}</Link>
        </li>
      ))}
    </ul>    
  )
}

export default Component;
