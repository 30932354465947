import {Link} from 'react-router-dom';
import { showDateTime, showPrice } from '../../libs/util';
import CurrencyIcon from '../common/icon';

const Component = ({transaction, onShow}) => {

  const order = transaction.withdrawal;

  return (
    <div className="nk-odr-item"> {/*is-cancelled*/}
      <div className="nk-odr-col">
          <div className="nk-odr-info">
              <div className="nk-odr-badge">
                  {/* {!! tnx_type_icon($transaction, 'odr-icon') !!} */}
                  {/* *Icon* */}
                  {/*<CurrencyIcon currency={order?.wallet?.currency}/>*/}
                  <CurrencyIcon currency={'wd'}/>
              </div>
              <div className="nk-odr-data">
                  <div className="nk-odr-label">
                      <strong className="ellipsis">
                          {/* @if($transaction->type == TransactionType::TRANSFER)
                              {{ ($transaction->calc == TransactionCalcType::DEBIT) ? __("Send Funds") : __("Receive Funds") }}
                          @else
                              {{ __(trans_replace($transaction->description)) }}
                          @endif */}
                          Withdraw {order?.wallet?.currency} to External Wallet

                      </strong>
                  </div>
                  <div className="nk-odr-meta">
                      <span className="date">
                          {transaction?.reference_id}
                      </span>
                  </div>
                  <div className="nk-odr-meta">
                      <span className="date">
                        {showDateTime(order?.transaction_date)}
                        {/* {{ ($transaction->status == TransactionStatus::COMPLETED) ? show_date($transaction->completed_at) : show_date($transaction->created_at) }} */}
                        </span>
                      <span className="status text-success">
                        {order?.trade_status}
                          {/* {{ __(data_get($transaction->details, 'status')) }} */}
                      </span>
                  </div>
              </div>
          </div>
      </div>
      <div className="nk-odr-col nk-odr-col-amount">
          <div className="nk-odr-amount">
              <div className="number-md text-s text-danger">
                  {/* {{ ($transaction->calc == TransactionCalcType::CREDIT) ? '+' : '-' }} {{ ($transaction->calc == TransactionCalcType::CREDIT) ? amount_z($transaction->amount, $base_currency, ['dp' => 'calc']): amount_z($transaction->total, $base_currency, ['dp' => 'calc']) }} */}
                  -{showPrice(order?.amount, order?.wallet?.currency)}
              </div>
              <div className="number-sm">
                {/* {{ ($transaction->calc == TransactionCalcType::CREDIT) ? amount_z($transaction->tnx_amount, $transaction->tnx_currency, ['dp' => 'calc']) : amount_z($transaction->tnx_total, $transaction->tnx_currency, ['dp' => 'calc']) }}  */}
                {/* *Amount* <span className="currency">ETH</span> */}
              </div>
          </div>
      </div>
      <div className="nk-odr-col nk-odr-col-action">
          <div className="nk-odr-action">
              <a className="tnx-details" href="#" data-toggle="modal" data-target="#txn-details" onClick={(e) => {e.preventDefault(); onShow()}}><em className="icon ni ni-forward-ios"></em></a>
          </div>
      </div>
    </div>

  )
}

export default Component;
