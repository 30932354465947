import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/auth';

import { EmailField, useValidation } from '../../components/form';
import { Errors } from '../../components/common/msg';

import { post } from '../../libs/api';

function Page() {
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState([]);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleSubmit = async () => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    // submitting
    const data = await post('/auth/forget', {email});
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }

    setTab(1);
  }
  
  return (
    <Layout>
      {tab == 0 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Reset Password</h4>
            <div className="nk-block-des mt-2">
              <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
            </div>
            <Errors errors={errors}/>
          </div>
        </div>

        <div className="form-validate is-alter">
        <div className="form-group">
          <div className="form-label-group">
              <label className="form-label" htmlFor="user-email">Email<span className="text-danger"> &nbsp;*</span></label>
          </div>
          <div className="form-control-wrap">
            <EmailField className="form-control form-control-lg" placeholder="Enter your email address"
                    value={email} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('email', valid)}
                    onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>

        <div className="form-group">
          <input type="hidden" id="recaptcha" value="" name="recaptcha"/>
          <button className="btn btn-lg btn-primary btn-block"
            onClick={handleSubmit}>Send Reset Link</button>
        </div>
        </div>

        <div className="form-note-s2 text-center pt-4">
          <Link to="/login"><strong>Return to login</strong></Link>
        </div>         
        </>
      )}

      {tab == 1 && (
        <>
        <div className="nk-block-head">
            <h4 className="nk-block-title">Password Recovery Request</h4>
        </div>
        <div className="nk-block-content">
            <p className="lead">We have emailed you instruction to reset your password. Please check your email.</p>
            <div className="gap gap-md"></div>
            <Link className="btn btn-lg btn-block btn-primary" to="/login">Return to login</Link>
        </div>
        </>
      )}

    </Layout>
  )
}

export default Page;
