import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import {post} from '../../libs/api';
import PersonalProfilForm from '../../components/user/personalProfileForm';
import FinancialProfileForm from '../../components/user/financialProfileForm';
import { Errors } from '../../components/common/msg';

function Page() {
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState(0);

  const [userData, setUserData] = useState(null);
  const [fields, setFields] = useState(null);
  const [errors, setErrors] = useState([]);
  const [kycToken, setKycToken] = useState(null);
  const [kycUrl, setKycUrl] = useState(null);

  const navigate = useNavigate();

  const updatePersonal = async(updated) => {
    setErrors([]);
    const data = await post('/profile/update', updated);
    if(data?.status == 200){
      setFields(updated);
      // setTab(3); // skip step 2
      submitVerify();
    }
    else{
      setErrors([data.error]);
    }
  }

  const financialProfileUpdated = async(updated) => {
    setErrors([]);
    const data = await post('/profile/update', updated);
    if(data?.status == 200){
      setFields(updated);
      setTab(3);
    }
    else{
      setErrors([data.error]);
    }
  }

  const kycUpdated = async() => {

  }

  const submitVerify = async() => {
    setErrors([]);
    const data = await post('/profile/submit');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }
    setKycInfo(data.data);
  }

  const loadProfile = async() => {
    const data = await post('/profile/get');

    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }
    //Redirect to Wait for Approval Page.
    if(data.data.status == 'SUBMITTED'){
      setTab(4);
      return;
    }
    //Redirect to Secret QnA
    if(data.data.status == 'APPROVED'){
      navigate('/sqna');
      return;
    }
    //Jump to KYC Launch If type been specified.
    if(data?.data?.fields.type && data?.data?.fields.nationality && data?.data?.fields.country){
      const retrieved = await post('/profile/retrieve');
      if(retrieved?.data?.kyc_application_id && retrieved?.data?.kyc_user_token){
        setKycInfo(retrieved.data);
        return;
      }

    }

    setUserData(data.data);
    setFields(data.data.fields);
    setTab(1);
  }

  const setKycInfo = (data) => {
    const appId = data.kyc_application_id;
    const token = data.kyc_user_token;
    const url = "https://nodeam.instance.kyc-chain.com/api/v2/applications/"+appId+"?access_token="+token;
    setKycToken(token);
    setKycUrl(url);
    setTab(3);
    if(token == null){
      setErrors(['System encountered errors while preparing the verification process.']);
    }
  }


  useEffect(() => {
    loadProfile();
  }, [])

  return (
    <Layout>
      <div className="nk-block-head">
        <div className="nk-block-head-content text-center">
          <h4 className="nk-block-title">Verification</h4>
        </div>
      </div>
      <Errors errors={errors}/>

      {tab == 1 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content text-center">
            <h6 className="nk-block-title">Complete account details</h6>
            {/* <div className="nk-block-des">
                <p>Basic info, like your name and address, that you use on our platform.</p>
            </div> */}
          </div>
        </div>
        <PersonalProfilForm uData={userData} fields={fields} onUpdate={updatePersonal}/>
        </>

      )}     

      {tab == 3 && (
        <>
        <div className="nk-block-head">
          <div className="nk-block-head-content text-center">
            <div className="nk-block-des">
              <p><strong>You are required to complete the verification process. </strong></p>
              <p>Please click the button to launch verification form.</p>
            </div>
          </div>
        </div>

        {kycToken && process.env.REACT_APP_IS_SKIP_KYC == 'false' &&
          <a href={kycUrl} className="btn btn-lg btn-block btn-primary" target="_self">Launch Verification Form</a>
        }

        {kycToken && process.env.REACT_APP_IS_SKIP_KYC == 'true' &&
            <a href={"/login"} className="btn btn-lg btn-block btn-primary" target="_self">Skip KYC, GO to Login</a>
        }

        </>
      )}       

      {tab == 4 && (
        <div className="nk-block-content text-center">
          <p><strong>Your verification is currently being processed. </strong></p>
          <p>We will send you an email when it's completed.</p>
          <div className="gap gap-md"></div>
          <Link className="btn btn-lg btn-block btn-primary" to="/login">Login</Link>
        </div>
      )}    
    </Layout>
  )
}

export default Page;
