export const UserStatus = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
} 

export const AuthStatus = {
  VERIFIED: 'VERIFIED',
  AUTHED: 'AUTHED',
  UNAUTHED: 'UNAUTHED'
}

export const AccType = [
  {value:"Individual", label:"Individual"},
  {value:"Corporate", label:"Corporate"}
]

export const UnsupportedCountries = [
  'KP', //Korea, Democratic People's Republic of
  'IR', //Iran, Islamic Republic of
  'AF', //Afghanistan
  'CD', //Congo, the Democratic Republic of the
  'LY', //Libya
  'SS', //South Sudan
  'SD', //Sudan
  'SO', //Somalia
  'SY', //Syrian Arab Republic
  'YE', //Yemen
  'IQ', //Iraq
  'HT', //Haiti
  'GW', //Guinea-Bissau
  'ML', //Mali
  'AL', //Albania
  'AO', //Angola
  'BY', //Belarus
  'BI', //Burundi
  'CF', //Central African Republic
  'ET', //Ethiopia
  'LR', //Liberia
  'LB', //Lebanon
  'UA', //Ukraine
  'RU', //Russian Federation
  'CI', //Côte d'Ivoire
  'ZW', //Zimbabwe
  'NI', //Nicaragua
]

export const UnsupportedNationalities = [
  'KP', //Korea, Democratic People's Republic of
  'IR', //Iran, Islamic Republic of
  'MM', //Myanmar
  'AF', //Afghanistan
  'CU', //Cuba
  'CD', //Congo, the Democratic Republic of the
  'IQ', //Iraq
  'LY', //Libya
  'RU', //Russian Federation
  'SO', //Somalia
  'SS', //South Sudan
  'SY', //Syrian Arab Republic
  'YE', //Yemen
  'VE', //Venezuela, Bolivarian Republic of
  'US', //United States
  'UM', //United States Minor Outlying Islands
  'VI', //Virgin Islands, U.S.
  'HT', //Haiti
  'GW', //Guinea-Bissau
  'ML', //Mali
  'AL', //Albania
  'AO', //Angola
  'BY', //Belarus
  'BI', //Burundi
  'CF', //Central African Republic
  'ET', //Ethiopia
  'LR', //Liberia
  'LB', //Lebanon
  'UA', //Ukraine
  'RU', //Russian Federation
  'CI', //Côte d'Ivoire
  'ZW', //Zimbabwe
  'NI', //Nicaragua
]
