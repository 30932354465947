import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import DepositForm from '../../components/wallet/depositFormNew';
import NeedVerification from '../../components/common/needVerification';
import SecurityVerification from '../../components/common/securityVerification';
import { getUser } from '../../libs/user';
import {Errors, Successes} from "../../components/common/msg";
import {post} from "../../libs/api";


function Page() {
  const [title, setTitle] = useState('Deposit');
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const [successes, setSuccesses] = useState([]);
  const [whitelists, setWhitelists] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [withdraw, setWithdraw] = useState({});
  const [step1Submitting, setStep1Submitting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const loadAddresses = async() => {
    setLoading(true);
    const data = await post('/wallet/deposit/address');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }
    setAddresses(data.data);
    setLoading(false);
  }

  const initLoad = async() => {
    setLoading(true);
    const user = await getUser();
    if(user){
      setVerified(user.profileVerified);
    }
    setLoading(false);
  }

  
  const loadWhitelists = async() => {
    setLoading(true);
    // const user = await getUser();
    // if(user){
    //   setVerified(user.profileVerified);
    // }

    const data = await post('/wallet/whitelist/deposit/list');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    } 
    let list = [];
    for(let item of data.data){
      if(item.status == 'VERIFIED'){
        if (item.address===null) {
          item.address='';
        }
        list.push(item);
      }
    }
    setWhitelists(list);

    const walletData = await post('/wallet/balance');
    if(walletData?.status == 200){
      setWallets(walletData.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadWhitelists();
  }, [])

  const handleStep1Submit = async (whitelist, wallet, amount, extBankRefNo, fileId1, fileId2, fileId3) => {
    setErrors([]);
    const params = {wallet_id: wallet?.wallet_id, whitelist_id: whitelist?.whitelist_id , amount}

    if(wallet?.currency !== "USD"){
      const data = await post('/wallet/deposit/validate', {
        wallet_id: wallet?.wallet_id ,
        whitelist_id: whitelist?.whitelist_id ?? "",
        amount
      });

      if(data?.status != 200){
        setSubmitting(false);
        setErrors([data.error]);
        return;
      }
      handleVerifySubmit(params);
    }else{
      handleVerifySubmit({ ...params, 
        ext_bank_ref_no : extBankRefNo, 
        att1_file_id: fileId1,
        att2_file_id: fileId2,
        att3_file_id: fileId3,})
    }
  }

  const handleVerifySubmit = async (params) => {
    setSubmitting(true);
    setErrors([]);
    setSuccesses([]);

    const data = await post('/wallet/request/new_deposit', params);
    if(data?.status != 200){
      setSubmitting(false);
      setErrors([data.error]);
      return;
    }
    navigate('/portal/deposited');
  }
  
  return (
    <Layout>

    {!loading && !verified && (
      <NeedVerification/>
    )}

    {errors.length > 0 && (
        <Errors errors={errors}/>
    )}

    {!loading && verified && (
      <div className="page-invest wide-xs m-auto" id="iv-step-container">
        <div className="nk-pps-apps">
          <div className="nk-pps-title text-center">
            <h3 className="title">{title}</h3>
            {/* <p className="caption-text">&nbsp;</p> */}
          </div>

          {successes.length > 0 && (
            <Successes msgs={successes}/>
          )}

          { <DepositForm whitelists={whitelists} wallets={wallets} submitting={submitting} onSubmit={handleStep1Submit}/>}

        </div>
      </div>
    )}
    </Layout>
  )
}

export default Page;
