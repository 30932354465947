import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import WalletNav from '../../components/wallet/nav';
import InvestmentRow from '../../components/transaction/investmentRow';
import TransactionRow from '../../components/transaction/row';

import InvestmentTransaction from '../../components/transaction/investment';
import DepositTransaction from '../../components/transaction/deposit';
import WithdrawalTransaction from '../../components/transaction/withdrawal';
import RedemptionTransaction from '../../components/transaction/redemption';
import SwapTransaction from '../../components/transaction/swap';

import Dialog from '../../components/common/dialog';
import { post } from '../../libs/api';
import { SelectField } from '../../components/form';
import ReactPaginate from 'react-paginate';


function Page() {

  const [title, setTitle] = useState('');
  const [active, setActive] = useState('all');
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortField, setSortField] = useState('p.create_time');

  const navs = [
    {key: 'all', 'text': 'All Transactions', 'title' : 'All Transactions'},
    {key: 'deposit', 'text': 'Deposit', 'title': 'Deposit Transactions'},
    {key: 'withdrawal', 'text': 'Withdraw', 'title': 'Withdraw Transactions'}
  ]

  const sortOptions = [
    {value: "p.create_time", label: "Transaction Date"},
    {value: "expiry_date", label: "Expiry Date"},
    {value: "reference", label: "Reference No"},
  ]

  const handleNav = (nav) => {
    setActive(nav);
    setPageOffset(0);
  }

  const loadTransactions = async(type) => {
    // set title
    for(const item of navs){
      if(item.key == type){
        setTitle(item.title);
        break;
      }
    }

    const result = await post('/wallet/transaction', {type, pageNum:pageOffset+1, pageSize: pageSize, sortField, sortOrder:"desc"});
    if(result?.status == 200){
      setTransactions(result.data)
      setTotal(result.total);
      setPageCount(Math.ceil(result.total / pageSize));
    }
  } 

  useEffect(() => {
    loadTransactions(active);
  }, [active, pageOffset, sortField]);

  const handlePageChange = async(event) => {
    setPageOffset(event.selected);
  }

  return (
    <Layout menu="transaction">
      <UserHead sub="Wallet Management" title="Transaction History" description="List of transactions in your account.">
        <ul className="nk-block-tools gx-3">          
          <li><Link to="/portal/deposit" className="btn btn-primary"><span>Deposit</span> <em className="icon ni ni-arrow-long-right"></em></Link></li>
        </ul>
      </UserHead>    

      <WalletNav active="transaction"/>

      <ul className="nk-nav nav nav-tabs">
        {navs.map((item, i) => (
          <li className={active == item.key ? 'nav-item active' : 'nav-item'} key={i}>
            <a className="nav-link" href="#" onClick={(e) => (e.preventDefault(), handleNav(item.key))}>{item.text}</a>
          </li>
        ))}
      </ul>
      {/* <div>
      Sort by:
          <SelectField className="form-control form-control-lg" placeholder="Please select"                    
            options={sortOptions}
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
          /> 
      </div> */}

      <div className="nk-block nk-block-xs">
        {transactions.length > 0 && (
        <div className="nk-block-head nk-block-head-sm">
          {/* <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h6 className="nk-block-title">{title}</h6>
            </div>
          </div> */}
          <form action="http://localhost:8888/transactions" method="GET">
              <div className="search-wrap search-wrap-extend bg-lighter" data-search="search">
                  <div className="search-content">
                      <a href="#" className="search-back btn btn-icon toggle-search" data-target="search"><em
                              className="icon ni ni-arrow-left"></em></a>
                      <input type="text" name="query" className="form-control border-transparent form-focus-none"
                              placeholder="Search by transaction id"/>
                      <button className="search-submit btn btn-icon mr-1"><em className="icon ni ni-search"></em></button>
                  </div>
              </div>
          </form>
        </div>
        )}

        <div className="nk-odr-list is-stretch card card-bordered mb-2">
          {transactions.length > 0 && transactions.map((t, i) => (
              <TransactionRow transaction={t} key={i} onShow={() => setTransaction(t)}/>
            )
          )}
          {transactions.length == 0 && (
            <div className="nk-odr-item">
              <div className="nk-odr-col">No transactions found!</div>
            </div>
          )}
        </div>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageOffset}
        />
        <div className="mt-4"></div>
      </div>

      <Dialog id="txn-details">
        {transaction?.investment && (
          <InvestmentTransaction transaction={transaction.investment} referenceId={transaction.reference_id}/>
        )}

        {transaction?.deposit && (
          <DepositTransaction transaction={transaction.deposit} referenceId={transaction.reference_id}/>
        )}

        {transaction?.withdrawal && (
          <WithdrawalTransaction transaction={transaction.withdrawal} referenceId={transaction.reference_id}/>
        )}
        {transaction?.redemption && (
            <RedemptionTransaction transaction={transaction.redemption} referenceId={transaction.reference_id}/>
        )}
        {transaction?.swap && (
            <SwapTransaction transaction={transaction.swap} referenceId={transaction.reference_id}/>
        )}
      </Dialog>

    </Layout>
  )
}

export default Page;
