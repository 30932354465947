import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import WalletNav from '../../components/wallet/nav';
import Dialog from '../../components/common/dialog';
import WhitelistForm from '../../components/wallet/whitelistForm';
import NeedVerification from '../../components/common/needVerification';
import { post } from '../../libs/api';
import { getUser } from '../../libs/user';

import { Errors, Successes } from '../../components/common/msg';
import Modal from 'react-bootstrap/Modal';

function Page() {

  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [errors, setErrors] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [acceptConfirmModal, setAcceptConfirmModal] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(0);


  const loadAddresses = async() => {
    setLoading(true);
    // const user = await getUser();
    // if(user){
    //   setVerified(user.profileVerified);
    // }

    const data = await post('/wallet/whitelist/list');
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }
    setAddresses(data.data);
    setLoading(false);
  }


  const handleConfirmModalClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setAcceptConfirmModal(false);
    setShowConfirmModal(false);
    setDeleteAddress(null);
  }
  const handleConfirmModalAccept = (e) => {
    if (e) {
      e.preventDefault();
    }

    setAcceptConfirmModal(true);
    setShowConfirmModal(false);

  }


  const handleConfirmDelete = async(address) => {

    if (!address.note)
      address.note = 'Address: ' + address.address;

    setDeleteAddress(address);
    setAcceptConfirmModal(false);
    setShowConfirmModal(true);
  }

  const sendDeleteAddress =  async() => {

    if (acceptConfirmModal && deleteAddress) {
      const data = await post('/wallet/whitelist/delete', {whitelist_id: deleteAddress.whitelist_id});
      if(data?.status != 200){
        setErrors([data.error]);
        return;
      }
      setErrors([]);
      loadAddresses();
    }


  }

  useEffect(() => {
    loadAddresses();
  }, [])

  useEffect(() => {
    sendDeleteAddress();
  }, [acceptConfirmModal, deleteAddress])



  return (
    <Layout>
      <UserHead sub="Wallet Management" title="Whitelist" description="List of transactions in your account.">
        <ul className="nk-block-tools gx-3">          
          <li><Link to="/portal/wallet/whitelist/add" className="btn btn-primary">
                <span>Add Whitelist Address</span> <em className="icon ni ni-arrow-long-right"></em></Link>
          </li>
        </ul>
      </UserHead>    

      <WalletNav active="whitelist"/>     
      {errors.length > 0 && (
        <Errors errors={errors}/>
      )}    

      <div className="nk-block nk-block-xs">
        {!loading && !verified && (
          <NeedVerification/>
        )}

        {!loading && verified && (
        <div className="card card-bordered">


          <table className="table table-ulogs">
            <thead className="thead-light">
            <tr>
              <th className="tb-col-os"><span className="overline-title">Purpose</span></th>
              <th className="tb-col-os"><span className="overline-title">Coin</span></th>
              <th className="tb-col-ip"><span className="overline-title">Address</span></th>
              <th className="tb-col-time"><span className="overline-title">Notes</span></th>
              <th className="tb-col-time"><span className="overline-title">Date</span></th>
              <th className="tb-col-time"><span className="overline-title">Status</span></th>
              <th className="tb-col-action"><span className="overline-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
            {addresses.map((a, i) => (
              <tr key={i}>
                <td className="tb-col-ip"><span className="sub-text">{a.purpose == 'WITHDRAW' ? 'Withdrawal' : 'Deposit'}</span></td>
                <td className="tb-col-os"><span className="sub-text">{a.currency} ({a.network})</span></td>
                <td className="tb-col-ip"><span className="sub-text">{a.address}</span></td>
                <td className="tb-col-ip"><span className="sub-text">{a.note}</span></td>
                <td className="tb-col-time"><span className="sub-text">{a.datecreate}</span></td>
                <td className="tb-col-ip"><span className="sub-text">{(a.status=='APPROVAL') ? 'PENDING' : a.status}</span></td>
                <td className="tb-col-action">

                  <a href="#" className="link-cross mr-sm-n1" onClick={(e) => {e.preventDefault(); handleConfirmDelete(a)}}>
                    <em className="icon ni ni-trash"></em></a>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>    
        )}    
      </div>


      <Modal dialogClassName={"confirm-dialog"} show={showConfirmModal} onHide={handleConfirmModalClose}>

        <div className="modal-header">
          <div className="modal-title h6">
            Confirmation
          </div>
          <button type="button" className="close" aria-label="Close"
                  onClick={handleConfirmModalClose}>
            X
          </button>
        </div>

        <Modal.Body>
          <div className="text-center">
            {deleteAddress && deleteAddress.note && (
                <p>
              Are you sure you want to delete {deleteAddress.note}?
            </p>
                )}

            <hr/>
          </div>

          <div className="text-center">
            <a href="#"
               className={"btn btn-block btn-primary"}
               onClick={handleConfirmModalAccept}>
              <span>Confirm</span>
            </a>
          </div>

        </Modal.Body>
      </Modal>

    </Layout>
  )
}

export default Page;
