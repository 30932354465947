import {useState, useEffect} from 'react';

import { PasswordField, useValidation } from '../../components/form';

import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, updatePassword,
  multiFactor, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator  } from 'firebase/auth';
import firebaseConfig from '../../config/firebase';
import {getUser} from '../../libs/user';
import { Errors, Successes } from '../common/msg';


const Component = ({onComplete, onCancel}) => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retPassword, setRetPassword] = useState('');

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const handleSubmit = async() => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    if(newPassword != retPassword){
      setErrors(['New passwords do not match.']);
      return;
    }

    const user = await getUser();
    if(!user){
      setErrors(['You are not login.']);
      return;
    }

    // check password
    try{
      const cred = await signInWithEmailAndPassword(auth, user.email, oldPassword);
      const update = await updatePassword(cred.user, newPassword);
      setOldPassword('');
      setNewPassword('');
      setRetPassword('');
      onComplete();
    }
    catch(err){
      console.log('firebase error', err);
      if(err.code == 'auth/wrong-password' || err.code == 'auth/user-not-found'){
        setErrors(['Invalid email or password.']);
      }
      else{
        setErrors(['Error updating password.']);
      }
    }
  }

  return (
    <div className="form-validate is-alter">
      {errors.length > 0 && (
        <Errors errors={errors}/>
      )}
      <div className="form-group">
        <label className="form-label" htmlFor="current-password">Current Password  <span className="text-danger">*</span></label>
        <div className="form-control-wrap">
          <PasswordField className="form-control form-control-lg" placeholder="Enter Current passcode"
                      value={oldPassword} required={true} minLength={8} showError={showError} test={false} 
                      onValidate={(valid) => setFieldValid('oldPassword', valid)}
                      onChange={(e) => setOldPassword(e.target.value)}/>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="new-password">New Password <span className="text-danger">*</span></label>
        <div className="form-control-wrap">
          <PasswordField className="form-control form-control-lg" placeholder="Enter New passcode"
                      value={newPassword} required={true} minLength={8} showError={showError} test={true}
                      onValidate={(valid) => setFieldValid('newPassword', valid)}
                      onChange={(e) => setNewPassword(e.target.value)}/>
        </div>
        <div className="form-control-wrap small">
          <i>* 12 characters or more, containing a digit, uppercase letter, and symbol</i>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="new-password">Retype New Password <span className="text-danger">*</span></label>
        <div className="form-control-wrap">
          <PasswordField className="form-control form-control-lg" placeholder="Retype New passcode"
                      value={retPassword} required={true} minLength={8} showError={showError} test={true}
                      onValidate={(valid) => setFieldValid('retPassword', valid)}
                      onChange={(e) => setRetPassword(e.target.value)}/>
        </div>
      </div>
      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
        <li><button type="button" className="btn btn-primary" onClick={handleSubmit}>Update Password</button></li>
        <li><a href="#" className="link link-light" onClick={(e) => {e.preventDefault(); onCancel()}}>Cancel</a></li>
      </ul>
    </div>    
  )
}

export default Component;
