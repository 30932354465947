import {useState, useEffect, useRef} from 'react';
import { isEmpty } from './func';
import { ageFromDate } from '../../libs/util';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Component = ({className, placeholder, value, onChange, onValidate, showError, minAge, minAgeMsg, required, disabled}) => {
  const [error, setError] = useState(null);
  // const [startDate, setStartDate] = useState(null);

  const validate = () => {
    if(!value){
      if(required){
        return 'Required.';
      }
      return null;
    }
    
    if(minAge && minAge > 0 && ageFromDate(value) < minAge){
      if(minAgeMsg){
        return minAgeMsg;
      }
      return 'You must be at least ' + minAge + ' years old.';
    }
    return null;
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [value])

  return (
    <div className="node-control">
      {/* <input type="text" className={className}
        placeholder={placeholder}  disabled={disabled}
        value={value}
        onChange={onChange}/> */}
      <DatePicker className={className} 
        placeholderText={placeholder}  disabled={disabled}
        dateFormat="dd/MM/yyyy" 
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        selected={value} onChange={onChange} />
      {error && showError && (
        <span className="invalid">{error}</span>
      )}
    </div>
  )
}

export default Component;
