import {useState, useEffect} from 'react';
import {Link, useSearchParams, useParams, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';

import NoFund from '../../components/invest/noFund';
import WhitelistForm from '../../components/wallet/whitelistForm';
import SecurityVerification from '../../components/common/securityVerification';
import NeedVerification from '../../components/common/needVerification';
import { post, upload } from '../../libs/api';
import { getUser } from '../../libs/user';
import { Errors, Successes } from '../../components/common/msg';

function Page() {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [whitelist, setWhitelist] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);

  const navigate = useNavigate();

  const resetError = () => {
    setErrors([]);
    if (document.getElementById("address"))
      document.getElementById("address").classList.remove('error');

    return;
  }


  const handleFormSubmitted = async (purpose, coin, network, address, notes, isHardware, vasp, vaspDetail, beneficiaryName, file) => {
    resetError();

    let fileId = null;
    if(!isHardware) {
      if(!vasp){
        setErrors(["Please provide the service provider "]);
        return;  
      }
      if(file?.files.length == 0){
        // setErrors(["Please upload proof of ownership "]);
        console.log("proof of ownership is optional for isHardware " + isHardware )
      }else{
        setSubmitting(true);
        // upload file and update fileId
        const data = await upload('/wallet/file/upload', file.files[0]);
        console.log("upload result", data);
        if(data?.status != 200){
          setSubmitting(false);
          setErrors(['Error uploading proof of ownership document']);
          return;
        }
        fileId = data.data.file_id;
      }
    }

    const data = await post('/wallet/whitelist/validate', {
      purpose,
      currency: coin,
      network,
      address,
      note: notes,
      is_hardware: isHardware,
      vasp,
      beneficiary_name: beneficiaryName,
      file_id: fileId
    });

    if(data?.status != 200){
      setSubmitting(false);
      setErrors([data.error]);

      if (data.error.startsWith("Address is not a valid blockchain wallet.") ||
          data.error.startsWith("There are already 2 whitelist added for") ||
          data.error.startsWith("Client Whitelist is existing")
      ) {
        if (document.getElementById("address"))
          document.getElementById("address").classList.add('error');
      }
      document.documentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

      return;
    }

    const params = {
      purpose,
      currency: coin, 
      network,
      address,
      note: notes,
      is_hardware: isHardware,
      vasp,
      vasp_detail: vaspDetail,
      beneficiary_name: beneficiaryName,
      file_id: fileId
    }

    handleVerifySubmit(params)
  }

  const handleVerifySubmit = async (params) => {
    setSubmitting(true);
    resetError();
    setSuccesses([]);

    const data = await post('/wallet/whitelist/add', params);
    if(data?.status != 200){
      setSubmitting(false);
      setErrors([data.error]);

      if (data.error == "Address is not a valid blockchain wallet.") {
        if (document.getElementById("address"))
          document.getElementById("address").classList.add('error');
      }
      document.documentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      return;
    }
    setSuccesses(['The whitelist address has been submitted.']);
    navigate("/portal/wallet/whitelist");
  }

  const initLoad = async() => {
    setLoading(true);
    const user = await getUser();
    if(user){
      setVerified(user.profileVerified);
    }
    setLoading(false);
  }


  useEffect(() => {
    // initLoad();
  }, [])

  return (
    <Layout>

      {!loading && !verified && (
        <NeedVerification/>
      )}

      <div className="page-invest wide-xs m-auto" id="iv-step-container">
        <div className="nk-pps-apps">
          {/* <div className="nk-pps-steps">
            <span className={step == 1 ? "step active" : "step"}></span>
            <span className={step == 2 ? "step active" : "step"}></span>
          </div> */}

          {errors.length > 0 && (
            <Errors errors={errors}/>
          )}
          {successes.length > 0 && (
            <Successes msgs={successes}/>
          )}


          {!loading && verified && (
            <>
              <div className="nk-pps-title text-center">
                <h3 className="title">Add New WhiteList Address</h3>
                <p className="caption-text"></p>
              </div>
              <WhitelistForm onSubmit={handleFormSubmitted} submitting={submitting}/>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Page;
