import './App.css';

import {BrowserRouter, Routes, Route, useRoutes} from 'react-router-dom';
import { UserProvider } from './libs/context';

import Home from './pages/public/home';

// auth
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import Verify from './pages/auth/verify';
import Reset from './pages/auth/reset';
import ForgotPassword from './pages/auth/forgot';
import Verification from './pages/auth/verification';
import SecretQnA from './pages/auth/secretqna';
import Agent from './pages/auth/agent';

// portal
import Portal from './Portal';
import NotFound from './pages/user/notFound';
import Maintenance from "./pages/public/maintenance";

import Dashboard from './pages/user/dashboard';
import ProfileSettings from './pages/user/profile/settings';
import ProfileSecurity from './pages/user/profile/security';
import ProfileVerification from './pages/user/profile/verification';
import ProfileActivity from './pages/user/profile/activity';

import Deposit from './pages/wallet/deposit';
import Deposited from './pages/wallet/deposited';
import Swap from './pages/wallet/swap';
import Swaped from './pages/wallet/swaped';
import Withdraw from './pages/wallet/withdraw';
import Withdrawed from './pages/wallet/withdrawed';

import Invest from './pages/invest/invest';
import InvestCC from './pages/invest/investcc';
import InvestCCPlus from './pages/invest/investccplus';
import Investment from './pages/invest/index';
import InvestmentOrder from './pages/invest/order';
import Plans from './pages/invest/plans';
import Planscc from './pages/invest/planscc';
import PlansCCPlus from './pages/invest/plansccplus';


import WalletBalances from './pages/wallet/balances';
import WalletTransaction from './pages/wallet/transaction';
import WalletWhitelist from './pages/wallet/whitelist';
import WalletWhitelistAdd from './pages/wallet/whitelistAdd';

import Transactions from './pages/transaction/index';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBitcoinSign } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  
  const faEth = {
    prefix: 'fas',
    iconName: 'eth',
    icon: [320, 512, ['eth'], 'feth', 'M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z']
  }
  library.add(faBitcoinSign, faEth);

  const faBtc = {
    prefix: 'fas',
    iconName: 'btc',
    icon: [512, 512, ['btc'], 'fbtc', 'M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-141.651-35.33c4.937-32.999-20.191-50.739-54.55-62.573l11.146-44.702-27.213-6.781-10.851 43.524c-7.154-1.783-14.502-3.464-21.803-5.13l10.929-43.81-27.198-6.781-11.153 44.686c-5.922-1.349-11.735-2.682-17.377-4.084l.031-.14-37.53-9.37-7.239 29.062s20.191 4.627 19.765 4.913c11.022 2.751 13.014 10.044 12.68 15.825l-12.696 50.925c.76.194 1.744.473 2.829.907-.907-.225-1.876-.473-2.876-.713l-17.796 71.338c-1.349 3.348-4.767 8.37-12.471 6.464.271.395-19.78-4.937-19.78-4.937l-13.51 31.147 35.414 8.827c6.588 1.651 13.045 3.379 19.4 5.006l-11.262 45.213 27.182 6.781 11.153-44.733a1038.209 1038.209 0 0 0 21.687 5.627l-11.115 44.523 27.213 6.781 11.262-45.128c46.404 8.781 81.299 5.239 95.986-36.727 11.836-33.79-.589-53.281-25.004-65.991 17.78-4.098 31.174-15.792 34.747-39.949zm-62.177 87.179c-8.41 33.79-65.308 15.523-83.755 10.943l14.944-59.899c18.446 4.603 77.6 13.717 68.811 48.956zm8.417-87.667c-7.673 30.736-55.031 15.12-70.393 11.292l13.548-54.327c15.363 3.828 64.836 10.973 56.845 43.035z']
  }
  library.add(faBitcoinSign, faBtc);

  const faCalendarCheck = {
    prefix: 'fas',
    iconName: 'calendar-check',
    icon: [448, 512, ['calendar-check'], 'fcalendar-check', 'M216.1 408.1C207.6 418.3 192.4 418.3 183 408.1L119 344.1C109.7 335.6 109.7 320.4 119 311C128.4 301.7 143.6 301.7 152.1 311L200 358.1L295 263C304.4 253.7 319.6 253.7 328.1 263C338.3 272.4 338.3 287.6 328.1 296.1L216.1 408.1zM128 0C141.3 0 152 10.75 152 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0zM400 192H48V448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192z']
  }
  library.add(faBitcoinSign, faCalendarCheck);

  const faBullseye = {
    prefix: 'fas',
    iconName: 'Bullseye',
    icon: [512, 512, ['Bullseye'], 'fBullseye', 'M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192zm64 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80zm0 64c-79.5 0-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144zm32-144c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z']
  }
  library.add(faBitcoinSign, faBullseye);

  const faBoxStack = {
    prefix: 'fas',
    iconName: 'box-stack',
    icon: [576, 512, ['box-stack'], 'fbox-stack', 'M248 0H208c-26.5 0-48 21.5-48 48V160c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V48c0-26.5-21.5-48-48-48H328V80c0 8.8-7.2 16-16 16H264c-8.8 0-16-7.2-16-16V0zM64 256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H184v80c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V256H64zM352 512H512c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H472v80c0 8.8-7.2 16-16 16H408c-8.8 0-16-7.2-16-16V256H352c-15 0-28.8 5.1-39.7 13.8c4.9 10.4 7.7 22 7.7 34.2V464c0 12.2-2.8 23.8-7.7 34.2C323.2 506.9 337 512 352 512z']
  }
  library.add(faBitcoinSign, faBoxStack);


  const faVault = {
    prefix: 'fas',
    iconName: 'vault',
    icon: [576, 512, ['vault'], 'fvault', 'M64 0C28.7 0 0 28.7 0 64V416c0 35.3 28.7 64 64 64H80l16 32h64l16-32H400l16 32h64l16-32h16c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM224 320c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80zm0 80c-88.4 0-160-71.6-160-160s71.6-160 160-160s160 71.6 160 160s-71.6 160-160 160zM480 221.3V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V221.3c-18.6-6.6-32-24.4-32-45.3c0-26.5 21.5-48 48-48s48 21.5 48 48c0 20.9-13.4 38.7-32 45.3z']
  }
  library.add(faBitcoinSign, faVault);

  const faArrowLeftRight = {
    prefix: 'fas',
    iconName: 'ArrowLeftRight',
    icon: [448, 512, ['ArrowLeftRight'], 'fArrowLeftRight', 'M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z']
  }
  library.add(faBitcoinSign, faArrowLeftRight);

  const faGuage = {
    prefix: 'fas',
    iconName: 'Guage',
    icon: [512, 512, ['Guage'], 'fGuage', 'M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM320 352c0-26.9-16.5-49.9-40-59.3V88c0-13.3-10.7-24-24-24s-24 10.7-24 24V292.7c-23.5 9.5-40 32.5-40 59.3c0 35.3 28.7 64 64 64s64-28.7 64-64zM144 176c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm-16 80c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm288 32c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zM400 144c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z']
  }
  library.add(faBitcoinSign, faGuage);

  const faCirclePlus = {
    prefix: 'fas',
    iconName: 'circle-plus',
    icon: [512, 512, ['CirclePlus'], 'fCirclePlus', 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z']
  }
  library.add(faBitcoinSign, faCirclePlus);


  const faClipboardList = {
    prefix: 'fas',
    iconName: 'clipboard-list',
    icon: [512, 512, ['ClipboardList'], 'fClipboardList', 'M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z']
  }
  library.add(faBitcoinSign, faClipboardList);


  const faCircleMinus = {
    prefix: 'fas',
    iconName: 'circle-minus',
    icon: [512, 512, ['CircleMinus'], 'fCircleMinus', 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z']
  }
  library.add(faBitcoinSign, faCircleMinus);

  const faCashRegister = {
    prefix: 'fas',
    iconName: 'cash-register',
    icon: [512, 512, ['CashRegister'], 'CashRegister', 'M64 0C46.3 0 32 14.3 32 32V96c0 17.7 14.3 32 32 32h80v32H87c-31.6 0-58.5 23.1-63.3 54.4L1.1 364.1C.4 368.8 0 373.6 0 378.4V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V378.4c0-4.8-.4-9.6-1.1-14.4L488.2 214.4C483.5 183.1 456.6 160 425 160H208V128h80c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H64zM96 48H256c8.8 0 16 7.2 16 16s-7.2 16-16 16H96c-8.8 0-16-7.2-16-16s7.2-16 16-16zM64 432c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm48-168a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm120-24a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM160 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM328 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM256 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM424 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM352 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48z']
  }
  library.add(faBitcoinSign, faCashRegister);

  const faHandHoldingDollar = {
    prefix: 'fas',
    iconName: 'hand-holding-dollar',
    icon: [576, 512, ['HandHoldingDollar'], 'fHandHoldingDollar', 'M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3 0 0c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z']
  }
  library.add(faBitcoinSign, faHandHoldingDollar);


  const faMoneyBillTransfer = {
    prefix: 'fas',
    iconName: 'money-bill-transfer',
    icon: [640, 512, ['MoneyBillTransfer'], 'fMoneyBillTransfer', 'M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z']
  }
  library.add(faBitcoinSign, faMoneyBillTransfer);

  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          <Route path="/verify/:code" element={<Verify />}/>
          <Route path="/reset/:code" element={<Reset />}/>
          <Route path="/password/forget" element={<ForgotPassword />}/>
          <Route path="/verification" element={<Verification />}/>

          <Route path="/sqna" element={<SecretQnA />}/>
          <Route path="/agent" element={<Agent />}/>

          <Route path="/maintenance" element={<Maintenance />}/>

          <Route path="portal" element={<Portal />}>
            <Route index element={<Planscc />}/>
            <Route path="profile" element={<ProfileSettings />}/>
            <Route path="profile/security" element={<ProfileSecurity />}/>
            {/* <Route path="profile/verification" element={<ProfileSettings />}/> */}
            <Route path="profile/activity" element={<ProfileActivity />}/>

            <Route path="deposit" element={<Deposit />}/>
            <Route path="deposit/:coin" element={<Deposit />}/>
            <Route path="deposited" element={<Deposited />}/>
            <Route path="swap/:swapCurrency" element={<Swap />}/>
            <Route path="swaped/:orderId" element={<Swaped />}/>
            <Route path="withdraw" element={<Withdraw />}/>
            <Route path="withdrawed" element={<Withdrawed />}/>
            <Route path="wallet" element={<WalletBalances />}/>
            <Route path="wallet/transaction" element={<Transactions />}/>
            <Route path="wallet/whitelist" element={<WalletWhitelist />}/>
            <Route path="wallet/whitelist/add" element={<WalletWhitelistAdd />}/>
            <Route path="transactions" element={<Transactions />}/>
            <Route path="plans" element={<Plans />}/>
            <Route path="plans/invest/:planType/:sourceCurrency" element={<Invest />}/>
            <Route path="planscc" element={<Planscc />}/>
            <Route path="planscc/invest/:sourceCurrency/:targetCurrency" element={<InvestCC />}/>

            <Route path="plansccplus" element={<PlansCCPlus />}/>
            <Route path="plansccplus/invest/:sourceCurrency/:targetCurrency" element={<InvestCCPlus />}/>
           
            <Route path="investment" element={<Investment />}/>
            <Route path="investment/order/:orderId" element={<InvestmentOrder isNew={false}/>}/>
            <Route path="investment/new_order/:orderId" element={<InvestmentOrder isNew={true}/>}/>

            <Route path="*" element={<NotFound />}/>

          </Route>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  )
}

export default App;

