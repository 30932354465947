import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserHead from '../../../components/user/head';
import Dialog from '../../../components/common/dialog';
import ProfileNav from '../../../components/user/profileNav';
import {InfoAlert, WarnAlert} from '../../../components/user/alert';
import ChangePassword from '../../../components/user/changePassword';
import { Successes } from '../../../components/common/msg';

import Modal from 'react-bootstrap/Modal';

function Page(props) {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [successes, setSuccesses] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  }
  const handleCloseForm = () => {
    setShowForm(false);
  }

  const handleUpdated = () => {    
    setSuccesses(['Your password has been updated.']);
    setShowForm(false);
  }

  return (
    <Layout menu="profile">  
      <UserHead title="Security Settings" description=""/>
      <ProfileNav active="security"/>

      <div className="nk-block">        
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title">Settings</h5>
          </div>
        </div>

        {successes.length > 0 && (
          <Successes msgs={successes}/>
        )}

        <div className="card card-bordered">
            <div className="card-inner-group">            
                <div className="card-inner">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                        <div className="nk-block-text">
                            <h6>Change Password</h6>
                            <p>Set a unique password to protect your account.</p>
                        </div>
                        <div className="nk-block-actions flex-shrink-sm-0">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                              <li className="order-md-last">
                                <a href="#" className="btn btn-sm btn-primary"
                                  onClick={(e) => {e.preventDefault(); handleShowForm()}}>Change Password</a>
                              </li>
                              <li>
                                <em className="text-soft text-date fs-12px">Last changed: <span>N/A</span></em>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="card-inner">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                        <div className="nk-block-text">
                            <h6>2FA Authentication <span className="badge badge-danger">Disabled</span></h6>
                            <p>Secure your account with 2FA security. When it is activated you will need to enter not only your password, but also a special code using your mobile.</p>
                        </div>
                        <div className="nk-block-actions">
                          <a href="#" id="settings-enable-2fa" data-toggle="modal" data-target="#enable-2fa" className="btn btn-sm btn-primary">Enable</a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
      </div>

      <Modal show={showForm} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ChangePassword onComplete={handleUpdated} onCancel={handleCloseForm}/>
        </Modal.Body>
      </Modal>
      {/* <Dialog id="change-password" title="Change Password">
        <ChangePassword/>
      </Dialog> */}

    </Layout>
)}

export default Page;
