import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Component = ({dtype, placeholder, selectedOption, options, onChange}) => {

  const handleClick = (event, option) => {
    event.preventDefault(); // Prevent the default anchor tag behavior
    onChange(option);
  };
  
  return (

    <div className="dropdown nk-pps-dropdown">
      <a href="#" className="dropdown-indicator" data-toggle="dropdown" id="iv-invest-scheme">
        <div className="nk-cm-item">
          <div className="nk-cm-icon">
              {(dtype=='expiry_date') ? <FontAwesomeIcon icon="calendar-check" className="icon"/> : ""}
              {(dtype=='strike_rate') ? <FontAwesomeIcon icon="Bullseye" className="icon"/> : ""}
          </div>


          {selectedOption && (
          <div className="nk-cm-text">                              
            <span className="label fw-medium">{selectedOption.name}</span>
            <span className="desc">
              {selectedOption.subtitle}
            </span>
          </div>
          )}

            {!selectedOption && (
                <div className="nk-cm-text">
                    <span className="desc">{placeholder}</span>
                </div>
            )}

        </div>
      </a>
      <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
        <ul className="nk-dlist">
          {options.map((x, i) => (
            <li className={x.id == selectedOption?.id ? "nk-dlist-item selected" : "nk-dlist-item"} key={i}>
              <a href="#" className="nk-dlist-opt" onClick={(event) => handleClick(event, x)}>
                <div className="nk-cm-item">
                  <div className="nk-cm-icon">
                      {(dtype=='expiry_date') ? <FontAwesomeIcon icon="calendar-check" className="icon"/> : ""}
                      {(dtype=='strike_rate') ? <FontAwesomeIcon icon="Bullseye" className="icon"/> : ""}
                  </div>
                  <div className="nk-cm-text">
                    <span className="label fw-medium">{x.name}</span>
                    <span className="desc">{x.subtitle}</span>
                  </div>
                </div>
            </a>
          </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Component;
