import {useState, useEffect, useRef} from 'react';
import { isEmpty } from './func';

const Component = ({className, placeholder,value, onChange, onValidate, showError, test, required, minLength, disabled}) => {
  const [error, setError] = useState(null);

  const [clear, setClear] = useState(false);

  const validate = () => {
    if(isEmpty(value)){
      if(required){
        return 'Required.';
      }
      return null;
    }
    if(minLength && minLength > 0){
      if(value.length < minLength){
        return 'At least ' + minLength + ' chars.';
      }
    }
    if(test){
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(!value.match(/[a-z]/) || !value.match(/[A-Z]/) || !value.match(/[0-9]/) || !specialChars.test(value)){
        return 'Must contain at least 1 digit, upper and lower case letter, and 1 symbol.';
      }
    }
    return null;
  }

  const toggleClear = () => {
    setClear(!clear);
  }

  useEffect(() => {
    let msg = validate();
    setError(msg);
    if(onValidate){
      onValidate(msg == null);
    }
  }, [value])

  return (
    <>
      <a tabIndex="-1" href="#" 
        onClick={toggleClear}
        className={"form-icon form-icon-right passcode-switch " + (clear ? 'is-shown' : 'is-hidden')}>
          <em className="passcode-icon icon-show icon ni ni-eye-off"/>
          <em className="passcode-icon icon-hide icon ni ni-eye"/>          
      </a>
      <input type={clear ? "text" : "password"} className={className}
        placeholder={placeholder}  disabled={disabled}
        value={value}
        onChange={onChange}/>
      {error && showError && (
        <span className="invalid">{error}</span>
      )}

    </>
  )
}

export default Component;
