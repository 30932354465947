import {useState, useEffect, useRef} from 'react';
import {secretQuestion1Options,secretQuestion2Options,secretQuestion3Options}  from '../../config/squestions';
import {SelectField, DateField, TextField, useValidation} from '../../components/form';


const Component = ({fields, onUpdate}) => {

    const [q1, setQ1] = useState('');
    const [a1, setA1] = useState('');
    const [q2, setQ2] = useState('');
    const [a2, setA2] = useState('');
    const [q3, setQ3] = useState('');
    const [a3, setA3] = useState('');

    const [showError, setShowError] = useState(false);
    const [setFieldValid, isValid] = useValidation();

    useEffect(() => {
        if (fields) {
            setQ1(fields.q1);
            setA1(fields.a1);

            setQ2(fields.q2);
            setA2(fields.a2);

            setQ3(fields.q3);
            setA3(fields.a3);
        }
    }, [fields])

    const handleSubmit = async (e) => {
        if (!isValid()) {
            setShowError(true);
            return;
        }

        // submit form
        if (onUpdate) {
            const updated = {...fields}
            updated.a1 = a1;
            updated.q1 = q1;

            updated.a2 = a2;
            updated.q2 = q2;

            updated.a3 = a3;
            updated.q3 = q3;

            onUpdate(updated);
        }
    }

    return (
        <div className="form-validate is-alter">
            <div className="row gy-4">

                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Secret Question 1 <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <SelectField className="form-control form-control-lg" placeholder="Please choose a Secret Question"
                                         required={true} showError={showError}
                                         onValidate={(valid) => setFieldValid('q1', valid)}
                                         options={secretQuestion1Options}
                                         value={q1}
                                         onChange={(e) => setQ1(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Answer <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <TextField className="form-control form-control-lg" placeholder="Answer to Secret Question 1"
                                       required={true} showError={showError}
                                       onValidate={(valid) => setFieldValid('a1', valid)} maxLength={190}
                                       value={a1}
                                       onChange={(e) => setA1(e.target.value)}
                            />
                        </div>
                    </div>
                    <hr/>
                </div>


                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Secret Question 2 <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <SelectField className="form-control form-control-lg" placeholder="Please choose a Secret Question"
                                         required={true} showError={showError}
                                         onValidate={(valid) => setFieldValid('q2', valid)}
                                         options={secretQuestion2Options}
                                         value={q2}
                                         onChange={(e) => setQ2(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Answer <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <TextField className="form-control form-control-lg" placeholder="Answer to Secret Question 2"
                                       required={true} showError={showError}
                                       onValidate={(valid) => setFieldValid('a2', valid)} maxLength={190}
                                       value={a2}
                                       onChange={(e) => setA2(e.target.value)}
                            />
                        </div>
                    </div>
                    <hr/>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Secret Question 3 <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <SelectField className="form-control form-control-lg" placeholder="Please choose a Secret Question"
                                         required={true} showError={showError}
                                         onValidate={(valid) => setFieldValid('q3', valid)}
                                         options={secretQuestion3Options}
                                         value={q3}
                                         onChange={(e) => setQ3(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="full-name">Answer <span
                                className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                            <TextField className="form-control form-control-lg" placeholder="Answer to Secret Question 3"
                                       required={true} showError={showError}
                                       onValidate={(valid) => setFieldValid('a3', valid)} maxLength={190}
                                       value={a3}
                                       onChange={(e) => setA3(e.target.value)}
                            />
                        </div>
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="form-group">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-2">
                            <li>
                                <button className="btn btn-lg btn-primary" onClick={handleSubmit}>Update</button>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Component;