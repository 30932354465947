import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import { Errors } from '../../components/common/msg';
import { post, get } from '../../libs/api';

import Plancc from '../../components/invest/plancc';
import useInterval from "../../libs/useInterval";
import {showPercent} from "../../libs/util";

function Page() {
  const [isTradeMtn, setIsTradeMtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [plans, setPlans] = useState([]);

  const checkTradeMtnStatus = async() => {
    const data = await post('/auth/mtn/trade');
    if(data?.data?.trademtn){
      // it is live
      setIsTradeMtn(false);
      setErrors([]);
    } else {
      setIsTradeMtn(true);
      setErrors(['Investment application is unavailable. Please try again later.']);

    }
  }

  const loadPlans = async () => {
    const data = await get('/investment/plan/cc/list');
    const usdProduct= {
      currency: "usd",
      display_title: "Penguin Secure USDT",
      source: "usd",
      tagline: "Earn yield on USDT",
      target: "usdt"
    };

    const usdtProduct= {
      currency: "usdt",
      display_title: "Penguin Secure US$ (Fiat)",
      maxYield: 0.0,
      minYield: 0.0,
      source: "usdt",
      tagline: "Earn yield on US$",
      target: "usd"
    };

    console.log(data);
    if(data?.status != 200){
      setErrors([data.error]);
      return;
    }

    let list = data.data;
    for(let item of list){
      if(item.currency == 'usdc'){
        item.display_title = 'Penguin Secure USDC'
        item.tagline = 'Earn yield on USDC '
        item.source = 'usdc';
        item.target = 'usdc';
      }
      else if(item.currency == 'usdt'){
        item.display_title = 'Penguin Secure USDT'
        item.tagline = 'Earn yield on USDT'
        item.source = 'usdt';
        item.target = 'usdt';
        usdProduct.maxYield = item.maxYield;
        usdProduct.minYield = item.minYield;
      } else if(item.currency == 'eth'){
        item.display_title = 'Penguin Secure ETH'
        item.tagline = 'Earn yield on ETH'
        item.source = 'eth';
        item.target = 'eth';
      }
    }
    const filteredList = list.filter(x => x.currency !== "eth");

    setPlans([ ...filteredList, usdProduct, usdtProduct]);
  }

  useEffect(() => {
    loadPlans();

  }, [])

  useInterval(() => {
    checkTradeMtnStatus();
  }, 5000);

  return (
    <Layout menu="plans">
      <div className="nk-content-body">
        <div className="nk-block-head text-center">
          <h2 className="nk-block-title fw-normal">Penguin Secure</h2>
          <div className="nk-block-des w-max-550px m-auto">
            <p className="lead"></p>
            <p><strong>Choose your preferred investment product to earn high returns.</strong></p>
          </div>
        </div>
        <div className="nk-ivp-apps">
          <div className="nk-ivp-plans row g-gs scheme-container justify-center">
            {errors?.length > 0 && <Errors errors={errors} />}

            {isTradeMtn == false &&
              plans.map((x, i) => (
                <div className="col-md-6 col-lg-6" key={i}>
                  <Plancc plan={x} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
