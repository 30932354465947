
const InfoAlert = ({children, message}) => {
  return (
    <div className="alert alert-primary alert-thick alert-plain">
      <div className="alert-cta flex-wrap flex-md-nowrap g-2">
        <div className="alert-text has-icon">
          <em className="icon ni ni-info-fill text-primary"></em>
          <p>{message}</p>
        </div>
        <div className="alert-actions my-1 my-md-0">
          {children}
        </div>
      </div>
    </div>
  )
}

const WarnAlert = ({children, message}) => {
  return (
    <div className="alert alert-warning alert-thick alert-plain">
      <div className="alert-cta flex-wrap flex-md-nowrap g-2">
        <div className="alert-text has-icon">
          <em className="icon ni ni-alert-fill text-warning"></em>
          <p>{message}</p>
        </div>
        <div className="alert-actions my-1 my-md-0">
          {children}
        </div>
      </div>
    </div>
  )
}

export {InfoAlert, WarnAlert};
