import {Link} from 'react-router-dom';
import CurrencyIcon from '..//common/icon';
import { showDateTime, showPrice } from '../../libs/util';

const Component = ({transaction, referenceId}) => {
  return (
    <>
    <div className="dark-text">TXN ID #{referenceId}</div>
    <div className="dark-text">Deposit {transaction.currency} via External Wallet</div>
    <div className="nk-odr-item p-0 pt-1 pb-2">
      <div className="nk-odr-col">
        <div className="nk-odr-info">
          <div className="nk-odr-badge">
            {/*<CurrencyIcon currency={transaction.currency}/> */}
            <CurrencyIcon currency={'de'}/>
          </div>
          <div className="nk-odr-data">
            <div className="nk-odr-label">{showPrice(transaction.amount, transaction.currency)}</div>
            <div className="nk-odr-meta">{showDateTime(transaction.transaction_date)}</div>
          </div>
        </div>
      </div>
      <div className="nk-odr-col nk-odr-col-amount text-right">{transaction.trade_status}</div>
    </div>

    <div className="mt-1 dark-text">DEPOSIT DETAILS</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Deposit Amount</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.currency)}</span>
      </div>
      <div className="col-6">
        <span className="lead-text">Credited in Wallet</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.currency)}</span>
      </div>
    </div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Detail</span>
        <span className="sub-text">Deposit {transaction.currency} via External Wallet</span>
      </div>
      <div className="col-6">
        <span className="lead-text">&nbsp;</span>&nbsp;
      </div>
    </div>

    <div className="mt-2 dark-text border-top pt-1">ADDITIONAL</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Deposit Method</span><span className="sub-text">External Wallet</span></div>
      <div className="col-6"><span className="lead-text">From</span><span className="sub-text">&nbsp;</span></div>
    </div>
    <div className="row mt-1">
      <div className="col-6"><span className="lead-text">Reference</span><span className="sub-text">N/A</span></div>
      <div className="col-6"><span className="lead-text">Updated Balance</span>
        <span className="sub-text">{showPrice(transaction.amount, transaction.currency)}</span></div>
    </div>

    <div className="mt-2 dark-text border-top pt-1">TIMELINE</div>
    <div className="row mt-1">
      <div className="col-6"><span className="lead-text">Requested On</span><span className="sub-text">{showDateTime(transaction.transaction_date)}</span></div>
      <div className="col-6"><span className="lead-text">Complete On</span><span className="sub-text">{showDateTime(transaction.transaction_date)}</span></div>
    </div>    
    </>
  )
}

export default Component;
